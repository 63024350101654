import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LocationActions from './location.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CountryService } from '../../services/CountryService';
import { StatesService } from '../../services/states.service';
import { SchoolService } from '../../services/school.service';
import { DataPage } from 'src/app/libs/shared/models';
@Injectable()
export class LocationEffects {

    laodallCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.LOAD_ALL_COUNTRIES),
            switchMap(() => {
                return this.countryService.getAllCountriesMinified().pipe(
                    map(data => LocationActions.LoadCountriesSuccess({ payload: data })),
                    catchError(error => of(LocationActions.LoadCountriesFailed(error))));
            }),
        );
    });

    loadStatesByCountry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.LOAD_STATES_BY_COUNTRY),
            switchMap((payload: any) => {
                return this.stateService.getStatesByCountry(payload.countryId).pipe(
                    map(response => LocationActions.LoadStatesByCountrySuccess({ payload: response.data })),
                    catchError(error => of(LocationActions.LoadStatesByCountryFailed({ error }))));
            }),
        );
    });

    loadDistrictByState$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.LOAD_DISTRICTS_BY_STATE),
            switchMap((payload: any) => {
                return this.stateService.getDistrictNameByState(payload.stateId).pipe(
                    map(response => LocationActions.LoadDistrictsByStateSuccess({ payload: response.data })),
                    catchError(error => of(LocationActions.LoadDistrictsByStateFailed({ error }))));
            }),
        );
    });

    loadSchoolsByFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.LOAD_SCHOOLS_BY_FILTER),
            switchMap((payload: any) => {
                const filterByDistricts = Array.isArray(payload.districtNames) && payload.districtNames.length > 0;
                // filter by state
                if (payload.stateId && !filterByDistricts) {
                    return this.schoolService.schoolListByState(payload.stateId, payload.page, payload.size).pipe(
                        map((response: DataPage<any>) => LocationActions.LoadSchoolListByFilterSuccess({ payload: response })),
                        catchError(error => of(LocationActions.LoadSchoolListByFilterFailed({ error }))));
                } else {
                    console.log('filter by districts');
                    console.log(payload);
                    return this.schoolService.schoollistByTownNames(payload.districtNames, payload.page, payload.size).pipe(
                        map((response: DataPage<any>) => LocationActions.LoadSchoolListByFilterSuccess({ payload: response })),
                        catchError(error => of(LocationActions.LoadSchoolListByFilterFailed(error))));

                }
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private countryService: CountryService,
        private stateService: StatesService,
        private schoolService: SchoolService) { }

}
