import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { AuthGuard } from './auth.guard';
import { PermissionsService } from './auth.persmissions';
import { Store, select } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import * as fromNav from '../../store/navigation';
import * as fromRoute from '../../store/router';
import { map, take, filter, mergeMap } from 'rxjs/operators';
import { User } from 'src/app/libs/shared/models';
import { UserGroup } from 'src/app/libs/shared/models/security/user-groups.enum';
import { environment } from 'src/environments/environment';
import { UserSchoolGrant } from 'src/app/libs/shared/models/user.model';
/**
 * @description
 *
 * @NgModule({
 *   imports: [
 *     RouterModule.forRoot([
 *       {
 *         path: 'root',
 *         canActivateChild: [CanActivateTeam],
 *         children: [
 *           {
 *              path: 'team/:id',
 *              component: TeamComponent
 *           }
 *         ]
 *       }
 *     ])
 *   ],
 *   providers: [CanActivateTeam, UserToken, Permissions]
 * })
 * class AppModule {}
 * ```
 *
 * You can alternatively provide a function with the `canActivateChild` signature:
 *
 * ```
 * @NgModule({
 *   imports: [
 *     RouterModule.forRoot([
 *       {
 *         path: 'root',
 *         canActivateChild: ['canActivateTeam'],
 *         children: [
 *           {
 *             path: 'team/:id',
 *             component: TeamComponent
 *           }
 *         ]
 *       }
 *     ])
 *   ],
 *   providers: [
 *     {
 *       provide: 'canActivateTeam',
 *       useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => true
 *     }
 *   ]
 * })
 * class AppModule {}
 * ```
 *
 * @publicApi
 */
@Injectable({ providedIn: 'root' })
export class UserAuthorizationGuard implements CanActivateChild {

    private isAuthedObservable = this.authStore.select(fromAuth.getLoggedIn);
    private route = this.routeStore.select(fromRoute.getRouterState);
    private user = this.authStore.select(fromAuth.getUser);

    // TODO use the user Store to get the current user and his roles and permissions.
    constructor(
        private permissions: PermissionsService,
        private authStore: Store<fromAuth.AuthState>,
        private routeStore: Store<fromRoute.RouterStateUrl>,
        private router: Router) {
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        return this.isAuthedObservable.pipe(
            mergeMap(isAuthed => this.route.pipe(
                mergeMap(routecontainer => this.user.pipe(
                    map(user => {
                        if (state.url.indexOf('miscellaneous') >= 0) {
                            return true;
                        }
                        if (isAuthed) {
                            const allows = routecontainer.state.data.allows || [];

                            const userGrants = user.userSchools
                                .reduce((acc: UserSchoolGrant[], val) => acc.concat(val.userSchoolGrants), []);

                            // console.log(userGrants);
                            const intersection = userGrants.some(grp =>
                                allows.includes(grp.grantedGroupName) || grp.grantedGroupName === UserGroup.All);

                            if (environment.debug) {
                                // console.log(routecontainer);
                            }
                            if (intersection) {
                                return true;
                            } else {
                                // const hasOwnerRight = allows.some(group => group === 'Owners');

                                // if (hasOwnerRight) {
                                //     routecontainer.state.
                                // }

                                this.router.navigate(['miscellaneous/error403']);
                                return true;
                            }
                        }
                        // return isAuthed;
                    })
                ))
            )),
            take(1)
        );
    }

}
