import { AuthActions, AuthActionTypes } from './auth.actions';
import { User } from 'src/app/libs/shared/models';
import { School } from 'src/app/libs/shared/models/school.model';
import { UserSchool } from 'src/app/libs/shared/models/user.model';
import { selectUserSchool } from './auth.model';

export interface AuthState {
  token: string;
  loading: boolean;
  error: any;
  user: User;
  selectedUserSchool: UserSchool;
  logged: boolean;
  loggedOnce: boolean;
}

export const authInitialState: AuthState = {
  token: null,
  loading: false,
  error: null,
  user: null,
  selectedUserSchool: null,
  logged: false,
  loggedOnce: false
};

export function authReducer(
  state = authInitialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoginAction:
      return {
        ...state,
        loading: true
      };

    case AuthActionTypes.LogoutAction:
    case AuthActionTypes.NullToken:
      return {
        ...state,
        token: null,
        loading: false,
        error: null,
        user: null,
        selectedUserSchool: null,
        logged: false
      };

    case AuthActionTypes.LoggedOnce:
      return {
        ...state,
        loggedOnce: action.payload
      };

    case AuthActionTypes.AuthTokenPayload:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload,
        selectedUserSchool: selectUserSchool(action.payload.userSchools),
        logged: true
      };
    case AuthActionTypes.TokenRestore:
      console.log('AuthActionTypes.TokenRestore');
      console.log(action);
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        selectedUserSchool: action.payload.selectedUserSchool,
        logged: true
      };
    case AuthActionTypes.TokenRefresh:
      return {
        ...state,
        loading: true,
        token: null,
      };
    case AuthActionTypes.TokenRefreshSuccess:
      console.log('Reducer [TokenRefreshSuccess] triggered');
      return {
        ...state,
        error: null,
        loading: false,
        token: action.payload
      };

    case AuthActionTypes.AuthFailure:
      return {
        ...state,
        error: action.payload,
        loading: false,
        user: null,
        selectedUserSchool: null,
        token: null,
        logged: false
      };
    case AuthActionTypes.ChangeUserSchoolSelection:
      return {
        ...state,
        selectedUserSchool: action.payload
      };

    default:
      return state;
  }
}
