import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-dropdown-apps',
  templateUrl: './dropdown-apps.component.html',
})
export class DropdownAppsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
