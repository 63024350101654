import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { User, SignUpUser } from 'src/app/libs/shared/models/user.model';
import { AuthToken } from 'src/app/libs/shared/models/auth-response.model';
import { SignOut } from 'src/app/libs/shared/models/auth.signOut.model';

@Injectable()
export class AuthService {
  isLoggedIn = false;
  MOCK_USER: User;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(private httpClient: HttpClient, private config: ConfigService) { }

  login(email: string, password: string): Observable<AuthToken> {
    const url = `${this.config.baseUrl}/auth/signin`;
    return this.httpClient
      .post<any>(url, { email, password })
      .pipe(
        tap(response => {
          if (response.accessToken) {
            this.isLoggedIn = true;
          }
        })
      );
  }

  getAuthUser(): Observable<User> {
    const url = `${this.config.baseUrl}/auth/account`;
    return this.httpClient.get<User>(url);
  }

  userRegistration(user: SignUpUser) {
    const url = `${this.config.baseUrl}/auth/signup`;

    return this.httpClient.post<User>(url, user);
  }

  logout(): Observable<boolean> {

    this.isLoggedIn = false;
    const url = `${this.config.baseUrl}/auth/tokens/cancel`;
    console.log(url);
    return this.httpClient.post<boolean>(url, {});
  }
}
