import { Action, createAction, props } from '@ngrx/store';

export const LOAD_ALL_COUNTRIES = 'ACTION_LOAD_ALL_COUNTRIES';
export const LoadCountries = createAction(
    LOAD_ALL_COUNTRIES,
);

export const LOAD_ALL_COUNTRIES_SUCCESS = 'LOAD_ALL_COUNTRIES_SUCCESS';
export const LoadCountriesSuccess = createAction(
    LOAD_ALL_COUNTRIES_SUCCESS,
    props<{ payload: any }>()
);

export const LOAD_ALL_COUNTRIES_FAIL = 'LOAD_ALL_COUNTRIES FAIL';
export const LoadCountriesFailed = createAction(
    LOAD_ALL_COUNTRIES_FAIL,
    props<{ error: any }>()
);

export const LOAD_STATES_BY_COUNTRY = 'LOAD_STATES_BY_COUNTRY';
export const LoadStatesByCountry = createAction(
    LOAD_STATES_BY_COUNTRY,
    props<{ countryId: any }>()
);

export const LOAD_STATES_BY_COUNTRY_SUCCESS = 'LOAD_STATES_BY_COUNTRY SUCCESS';
export const LoadStatesByCountrySuccess = createAction(
    LOAD_STATES_BY_COUNTRY_SUCCESS,
    props<{ payload: any }>()
);

export const LAOD_STATES_BY_COUNTRY_FAIL = 'LAOD_STATES_BY_COUNTRY FAIL';
export const LoadStatesByCountryFailed = createAction(
    LAOD_STATES_BY_COUNTRY_FAIL,
    props<{ error: any }>()
);

export const LOAD_DISTRICTS_BY_STATE = 'LOAD_DISTRICTS_BY_STATE';
export const LoadDistrictsByState = createAction(
    LOAD_DISTRICTS_BY_STATE,
    props<{ stateId: any }>()
);

export const LAOD_DISTRICTS_BY_STATE_SUCCESS = 'LAOD_DISTRICTS_BY_STATE SUCCESS';
export const LoadDistrictsByStateSuccess = createAction(
    LAOD_DISTRICTS_BY_STATE_SUCCESS,
    props<{ payload: any }>()
);

export const LAOD_DISTRICTS_BY_STATE_FAILED = 'LAOD_DISTRICTS_BY_STATE FAILED';
export const LoadDistrictsByStateFailed = createAction(
    LAOD_DISTRICTS_BY_STATE_FAILED,
    props<{ error: any }>()
);
// ==============================================================================
export const LOAD_SCHOOLS_BY_FILTER = 'LOAD_SCHOOLS_BY_FILTER';
export const LoadSchoolListByFilter = createAction(
    LOAD_SCHOOLS_BY_FILTER,
    props<{ countryId: number, stateId: number, districtNames: string[], page: number, size: number }>()
);

export const LOAD_SCHOOLS_BY_FILTER_SUCCESS = 'LOAD_SCHOOLS_BY_FILTER SUCCESS';
export const LoadSchoolListByFilterSuccess = createAction(
    LOAD_SCHOOLS_BY_FILTER_SUCCESS,
    props<{ payload: any }>()
);

export const LOAD_SCHOOLS_BY_FILTER_FAILED = 'LOAD_SCHOOLS_BY_FILTER FAILED';
export const LoadSchoolListByFilterFailed = createAction(
    LOAD_SCHOOLS_BY_FILTER_FAILED,
    props<{ error: any }>()
);






