import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NzNotificationService } from 'ng-zorro-antd/notification';

declare var $: any;

@Injectable()
export class NotificationService {


  constructor(private toastr: ToastrService, private notification: NzNotificationService) {
  }

  smallBox(data, cb?) {
    // $.smallBox(data, cb);
  }

  bigBox(data, cb?) {
    // $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    // $.SmartMessageBox(data, cb);
  }

  public success(title: string, message: string) {
    // this.toastr.success(message, title);

    this.notification.create('success', title, message);
  }

  public info(title: string, message: string) {
    // this.toastr.info(message, title);

    this.notification.create('info', title, message);
  }

  public warning(title: string, message: string) {
    // this.toastr.warning(message, title);
    this.notification.create('warning', title, message);
  }

  public error(title: string, message: string) {
    // this.toastr.error(message, title);

    this.notification.create('error', title, message);
  }

  public confirm(title: string, message: string) {
    this.notification.blank(
      title,
      message,
      { nzDuration: 0 }
    );
  }

}
