import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RegisterActionTypes } from './register.actions';
import { map, switchMap, catchError, tap, exhaustMap, delay } from 'rxjs/operators';
import * as actions from './register.actions';
import { of } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { StatesService } from '../../services/states.service';
import { SchoolService } from '../../services/school.service';
import { AuthService, NotificationService } from '../../services';
import { SignUpUser, User } from 'src/app/libs/shared/models';



@Injectable()
export class RegisterEffects {
  loginUrl = '/auth/login';
  constructor(
    private actions$: Actions,
    private statesService: StatesService,
    private schoolService: SchoolService,
    private authService: AuthService,
    private router: Router, private notify: NotificationService) { }

  @Effect({ dispatch: true })
  initState$ = this.actions$.pipe(
    ofType(RegisterActionTypes.initState),
    tap((payload) => {
      // log initialisation
    })
  );

  @Effect({ dispatch: true })
  requestStates$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadStates),
    map((action: any) => action.payload),
    exhaustMap((payload: any) => {
      return this.statesService.getStatesByCountry(payload)
        .pipe(
          map((response: any) => {
            return new actions.LaodStatesSuccessAction(response.data);
          }),
          catchError((error: any) => {
            return of(new actions.RegisterUserFailed(error));
          })
        );
    })
  );
  @Effect({ dispatch: false })
  statesLoaded$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadStatesSuccess),
    tap((payload: any) => {
      // TODO log the payload
    })
  );

  @Effect({ dispatch: true })
  requestDistircts$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadDistricts),
    // map((action: any) => action.payload),
    exhaustMap((action: any) => {
      return this.statesService.getDistrictNameByState(action.playload)
        .pipe(
          map((response: any) => {
            console.log(response);
            return new actions.LoadDistrictsSuccessAction(response.data);
          }),
          catchError((error: any) => {
            return of(new actions.RegisterUserFailed(error));
          })
        );
    }),
  );
  @Effect({ dispatch: false })
  districtsLoaded$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadDistrictsSuccess),
    tap((payload: any) => {
      // console.log(payload);
      // TODO log the payload
    })
  );

  @Effect({ dispatch: true })
  districtSelected$ = this.actions$.pipe(
    ofType(RegisterActionTypes.selectedDistrict)
  );

  @Effect({ dispatch: true })
  schools$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadSchools),
    exhaustMap((action: any) => {
      return this.schoolService.getSchoolNameListByDistrict(action.payload)
        .pipe(
          map((response: any) => {
            return new actions.LoadSchoolsSuccessAction(response.data);
          }),
          catchError((error: any) => of(new actions.RegisterUserFailed(error)))
        );
    })
  );

  @Effect({ dispatch: false })
  schoolsLoaded$ = this.actions$.pipe(
    ofType(RegisterActionTypes.loadSchoolsSuccess),
    tap((payload: any) => {
      return payload;
      // console.log(payload);
      // TODO log the payload
    })
  );

  @Effect({ dispatch: true })
  register$ = this.actions$.pipe(
    ofType(RegisterActionTypes.registerUser),
    exhaustMap((action: any) => {
      // call the authservice to signup
      const user: User = action.payload;

      const signupUser: SignUpUser = {
        PhoneNr: user.userProfile.alternateNr,
        email: user.email,
        firstname: user.userProfile.firstname,
        lastname: user.userProfile.lastname,
        mobileNr: user.userProfile.mobileNr,
        password: user.password,
        salutation: user.userProfile.salutation,
        title: user.userProfile.title

      };
      return this.authService.userRegistration(signupUser)
        .pipe(
          map((regResponse: any) => {
            return new actions.RegisterUserSuccessAction(regResponse.data);
          }),
          catchError((httpErrorResponse: HttpErrorResponse) => {
            return of(new actions.RegisterUserFailed(httpErrorResponse.error));
          })
        );
    })
  );

  @Effect({ dispatch: false })
  registerSuccess$ = this.actions$.pipe(
    delay(2500),
    ofType(RegisterActionTypes.registerUserSuccess),
    map((action: any) => {
      this.notify.success('Awesome!', 'Your account is ready.');
      this.router.navigate([this.loginUrl]);
    }),
    map(_ => new actions.InitStateAction())

  );

  @Effect()
  registerFailed$ = this.actions$.pipe(
    ofType(RegisterActionTypes.registerFailed),
    map((action: any) => action.payload),
    tap((error: any) => {
      if (error) {
        this.notify.error('Validation Error', error.data);
      }
    })
  );
}
