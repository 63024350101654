import { Injectable } from '@angular/core';
import * as fromAuth from '../../core/store/auth';
import * as fromUserProfile from '../../core/store/profile';
import { createSelector, Store } from '@ngrx/store';
import { IUserFacade } from './interfaces/user-interface.facade';
import { User, UserSchoolGrant } from '../shared/models/user.model';
import { UserGroup } from '../shared/models/security/user-groups.enum';
import { UserRight } from '../shared/models/security/user-rights.enum';
import { NotificationService, UserService } from 'src/app/core/services';
import { catchError, map, reduce } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataPage } from '../shared/models/page.model';
import { UserProfileData } from '../shared/models/user-profile-update-request';

@Injectable({ providedIn: 'root' })
export class UserFacade implements IUserFacade {


    private authedUser: User;
    private profileVm: any;

    public observaleUser = this.authStore.select(fromAuth.getUser);
    public selectedUserSchool$ = this.authStore.select(fromAuth.SelectedUserSchool);
    public userGrants$ = this.authStore.select(fromAuth.UserGrants);
    public profileUpdateLoaingState = this.profileStore.select(fromUserProfile.profileUpdateLoading);

    public observableProfileVM = this.profileStore.select(fromUserProfile.getProfileVM);

    public vm$ = this.store.select(createSelector(
        fromAuth.getAuthState, fromUserProfile.getProfileVM,
        (authState, profileVm) => (
            {
                user: authState.user,
                profileModel: profileVm.model,
                selectedUserSchool: authState.selectedUserSchool,
            }
        )
    ));
    constructor(
        private authStore: Store<fromAuth.AuthState>,
        private profileStore: Store<fromUserProfile.ProfileState>,
        private store: Store<any>, private userService: UserService, private notificationService: NotificationService) {

        this.observaleUser.subscribe(user =>
            this.authedUser = user
        );

        this.observableProfileVM.subscribe(vm => {
            this.profileVm = vm;
            // console.log(vm);
        });
    }

    getPagedUsersBySchool(schoolId: number, page: number, pageSize: number): Observable<DataPage<User>> {
        return this.userService.getUsersByPageAndSchoolId(schoolId, page, pageSize);
    }

    createSchoolGroup(request: any) {
        return this.profileStore.dispatch(new fromUserProfile.CreateUserSchoolGrp(request));
    }
    isProfileLoading() {
        return this.profileStore.select(fromUserProfile.getProfileLoading);
    }
    disjoinSchool(userId: number, schoolId: number, ignoreIDentity: boolean) {

        this.profileStore.dispatch(new fromUserProfile.DisjoinSchool(userId, schoolId, ignoreIDentity));

    }
    joinSchool(userId: number, schoolId: number, ignoreIDentity: boolean) {
        this.profileStore.dispatch(new fromUserProfile.JoinSchool(userId, schoolId, ignoreIDentity));
    }

    getAuthedUserGroups() {
        return this.profileVm.user.userGroups;
    }

    getAuthedUserRights() {
        throw new Error('Method not implemented.');
    }

    /**
     * check the user if he is granted to a specific group
     * @param grp the group you want to check if the user is granted to
     */
    isAuthedUserInGroup(grp: UserGroup): Observable<boolean> {

        return this.vm$.pipe(
            map(vm => vm.selectedUserSchool),
            map(selectedUserSchool => {
                if (selectedUserSchool) {

                    const schoolGrants = selectedUserSchool.userSchoolGrants;

                    const hasGroup = schoolGrants.some(userGrp => userGrp.grantedGroupName === grp);

                    return hasGroup;
                }
            })
        );
    }

    isOwner(requestUserId: number): boolean {
        if (this.authedUser) {

            return this.authedUser.id === requestUserId;
        }

        return false;
    }

    HasAuthedUserTheRight(right: UserRight) {
        throw new Error('Method not implemented.');
    }

    getAuthenticatedUser() {
        return this.authedUser;
    }
    createEntity(entity: User) {
        throw new Error('Method not implemented.');
    }
    getById(id: any) {
        return this.userService.getUserById(id);
    }
    delete(id: any) {
        return this.userService.delete(id);
    }
    getByPage(page: number, size: number) {
        throw new Error('Method not implemented.');
    }

    UpdateUserProfile(profileData: UserProfileData, userId: number) {
        this.profileStore.dispatch(new fromUserProfile.UserProfileUpdate({ profileData, userId }));
    }

}
