import * as fromAuth from './auth.reducer';

import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserSchoolGrant } from 'src/app/libs/shared/models/user.model';


export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');


export const getAuthLoading = createSelector(getAuthState, (state: fromAuth.AuthState) => state.loading);

export const getAuthError = createSelector(getAuthState, (state: fromAuth.AuthState) => state.error);

export const getUser = createSelector(getAuthState, (state: fromAuth.AuthState) => state.user);

// export const getToken = createSelector(getAuthState, (state: fromAuth.AuthState) => state.);


export const UserGrants = createSelector(getAuthState, (state: fromAuth.AuthState) => state.user.userSchools
    .reduce((acc: UserSchoolGrant[], val) => acc.concat(val.userSchoolGrants), []));

export const SelectedUserSchool = createSelector(getAuthState, (state: fromAuth.AuthState) => state.selectedUserSchool);

export const UserSchools = createSelector(getAuthState, (state: fromAuth.AuthState) =>
    state.user.userSchools.map(userSchool => userSchool.school));

export const getLoggedIn = createSelector(getAuthState, (state: fromAuth.AuthState) => {

    return !!state.user;
});

