import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleFilter } from 'src/app/store/navigation';
import { APP_CONFIG } from 'src/app/app.config';
import { UserFacade } from 'src/app/libs/facades/user-management.facade';

@Component({
  selector: 'smart-nav-info-card',
  templateUrl: './nav-info-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavInfoCardComponent implements OnInit {

  user = APP_CONFIG.user;
  avatar = APP_CONFIG.avatar;
  atId: string;

  userProfileState: any;
  vm$ = this.userFacade.vm$;

  constructor(private store: Store<any>, private userFacade: UserFacade) { }

  ngOnInit(): void {
    this.userFacade.observableProfileVM.subscribe(profileState => {
      this.userProfileState = profileState;
      this.avatar = this.userProfileState.model.pic;
      this.user = this.userProfileState.user.userProfile.firstname + ' ' + this.userProfileState.user.userProfile.lastname;
      this.atId = '@' + this.userProfileState.user.userProfile.firstname + this.userProfileState.user.userProfile.lastname;;

    });
  }

  toggleFilter($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleFilter());

  }
}
