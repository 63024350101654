import { Injectable } from '@angular/core';
import { MessageType } from './notify.reducer';
import { NotificationService } from '../../services';

@Injectable()
export class NotifyService {
  constructor(private notificationService: NotificationService) { }

  public showError = error => {
    // @todo show error
  }

  public snackNotify = (message: any, action: string = 'Ok', duration: number = 7000) => {
    // @todo show notify
    switch (message.type) {
      case MessageType.INFO:
        this.smallboxinfo(message);
        break;
      case MessageType.SUCCESS:
        this.smallboxSuccess(message);
        break;
      case MessageType.ERROR:
        this.smallboxError(message);
        break;
      case MessageType.WARNING:
        this.smallboxWarning(message);
        break;
      default:
        this.smallboxinfo(message);

    }
  }

  private smallboxSuccess(message) {
    this.notificationService.success(message.title, message.message);
    // this.notificationService.smallBox({
    //   title: message.title,
    //   content: message.message,
    //   color: '#739E73',
    //   iconSmall: 'fa fa-cloud',
    //   timeout: 5000
    // });
  }

  private smallboxinfo(message) {
    this.notificationService.info(message.title, message.message);
    // this.notificationService.smallBox({
    //   title: message.title,
    //   content: message.message,
    //   color: '#296191',
    //   iconSmall: 'fa fa-cloud',
    //   timeout: 5000
    // });
  }

  private smallboxWarning(message) {
    this.notificationService.warning(message.title, message.message);
    // this.notificationService.smallBox({
    //   title: message.title,
    //   content: message.message,
    //   color: '#915b29',
    //   iconSmall: 'fa fa-cloud',
    //   timeout: 5000
    // });
  }

  private smallboxError(message) {
    this.notificationService.error(message.title, message.message);
    // this.notificationService.smallBox({
    //   title: message.title,
    //   content: message.message,
    //   color: '#912929',
    //   iconSmall: 'fa fa-cloud',
    //   timeout: 5000
    // });
  }
}
