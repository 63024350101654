import { UserSchoolGroup } from 'src/app/libs/shared/models';
import * as SchoolGroupActions from './school-group.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface SchoolGroupState {
    schoolGroup: UserSchoolGroup;
    loading: boolean;
    error: any;
}

export const intialSchoolGroupState: SchoolGroupState = {
    schoolGroup: null,
    error: null,
    loading: false,
};

const SchoolGroupReducer = createReducer(
    intialSchoolGroupState,
    on(SchoolGroupActions.GetSchoolGroupDetails, (state, action) => {
        return ({
            ...state,
            loading: true,
        });
    }),
    on(SchoolGroupActions.GetSchoolGroupDetailsSuccess, (state, action) => {
        return ({
            ...state,
            schoolGroup: action.data,
            loading: false,
        });
    }),
    on(SchoolGroupActions.GetSchoolGroupDetailsFailed, (state, action) => {
        return ({
            ...state,
            error: action.error,
            loading: false,
        });
    })
);

export function reducer(state: SchoolGroupState | undefined, action: Action) {
    return SchoolGroupReducer(state, action);
}
