import { Action } from '@ngrx/store';
import { NotifyActions, NotifyActionTypes } from './notify.actions';

export enum MessageType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
  FATAL,
  NONE
}
export interface NotifyState {
  error: {
    type: MessageType;
    code?: string;
    title: string
    message: string;
  };
}

export const initialNotifyState: NotifyState = {
  error: {
    type: MessageType.NONE,
    code: null,
    title: null,
    message: null
  }
};

export function notifyReducer(
  state = initialNotifyState,
  action: NotifyActions
): NotifyState {
  switch (action.type) {
    case NotifyActionTypes.ShowError:
      return {
        ...state,
        error: {
          type: action.payload.type,
          code: action.payload.code,
          title: action.payload.title,
          message: action.payload.message
        }
      };
    case NotifyActionTypes.SnackNotify:
      return {
        ...state,
        error: {
          type: action.payload.type,
          code: action.payload.code,
          title: action.payload.title,
          message: action.payload.message
        }
      };
    case NotifyActionTypes.SnackNotify:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
}
