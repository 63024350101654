import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataPage, publicHoliday } from 'src/app/libs/shared/models';
import { ApiResponse } from 'src/app/libs/shared/models/api-response.model';

@Injectable()
export class HolidaysService {
  private schoolHolidaysSubject: BehaviorSubject<DataPage<any>>;
  public schoolHolidays: Observable<DataPage<any>>;

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.schoolHolidaysSubject = new BehaviorSubject<DataPage<any>>(new DataPage());
    this.schoolHolidays = this.schoolHolidaysSubject.asObservable();
  }

  // getAllSchoolHolidays() {
  //   const url = `${this.config.baseUrl}/schoolvacations`;

  //   return this.httpClient.get<DataPage>(url).pipe(
  //     map((result: DataPage) => {
  //       this.schoolHolidaysSubject.next(result);
  //     })
  //   );
  // }

  // getHolidaysBySchoolAndYear(schoolId: number, year: number) {
  //   const url = `${this.config.baseUrl}/schools/${schoolId}/${year}/Holidays`;

  //   return this.httpClient.get<ApiResponse<publicHoliday[]>>(url);
  // }

  // getValidHolidaysBySchoolAndYear(schoolId: number, year: number) {
  //   const url = `${this.config.baseUrl}/schools/${schoolId}/${year}/NextValidHolidays`;

  //   return this.httpClient.get<ApiResponse<publicHoliday[]>>(url);
  // }

  // getValidHolidaysByStateAndYear(stateCode: string, year: number) {
  //   const url = `${this.config.baseUrl}/schools/ByStateCode/${stateCode}/Year/${year}/NextValidHolidays`;

  //   return this.httpClient.get<ApiResponse<publicHoliday[]>>(url);
  // }

  addNewPublicHoliday(publiHoliday: any) {
    const url = `${this.config.baseUrl}/publicholidays`;
    return this.httpClient.post<any>(url, publiHoliday);
  }
}
