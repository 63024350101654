import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './auth.actions';
import * as profileActions from '../profile/profile.actions';
import {
  tap,
  filter,
  switchMap,
  map,
  catchError,
  mergeMap,
  exhaustMap
} from 'rxjs/operators';

import { AuthState } from './auth.reducer';
import { Store, Action } from '@ngrx/store';
import { AuthTokenService } from '../../services/auth-token.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService, NotificationService } from '../../services';
import { AuthToken, SignOut } from 'src/app/libs/shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';
import { UserSchool } from 'src/app/libs/shared/models/user.model';

@Injectable()
export class AuthEffects {
  redirectUrl = '/dashboard';
  loginUrl = '/auth/login';

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private authToken: AuthTokenService,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private configService: ConfigService
  ) { }

  @Effect({ dispatch: true })
  login$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginAction),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return this.authService.login(payload.email, payload.password)
        .pipe(
          map((authResponse: AuthToken) => {
            //
            // inject the token
            //
            this.authToken.trancateLocalStorageDb();
            this.authToken.token$.next(authResponse.accessToken);

            return authResponse.accessToken;
          }),
          catchError((error: any) => {
            console.log(error);
            if (error instanceof HttpErrorResponse) {
              this.notificationService.error(
                this.translate.instant('errors.auth_title'),
                this.translate.instant(`errors.${error.error.code}`));
            }
            return of(new actions.AuthFailure(error));
          })
        );
    }),
    map((actiond: any) => {
      if (actiond.type === actions.AuthActionTypes.AuthFailure) {
        return of(new actions.AuthFailure(actiond.payload));
      } else {
        return new actions.TokenRefresh();
      }

    }),
    catchError(error => {
      return of(new actions.AuthFailure(error));
    }),
    switchMap(() => {
      return this.authService.getAuthUser()
        .pipe(
          map((authUserPayload: any) => {
            return new actions.AuthUserChange(authUserPayload.data);
          }),
          catchError((error: any) => {
            return of(new actions.AuthFailure(error));
          })
        );
    })
  );

  @Effect()
  userProfileUpdated$ = this.actions$.pipe(
    ofType(profileActions.ProfileActionTypes.PROFILE_UPDATE_USER),
    map((action: any) => {
      return new actions.AuthUserChange(action.user);
    })
  );
  @Effect()
  updateUserProfile$ = this.actions$.pipe(
    ofType(profileActions.ProfileActionTypes.UPDATE_USER_PROFILE_SUCCESS),
    map((action: any) => {
      this.notificationService.success('Hourra!', this.translate.instant('profile.profile_update_success'));
      return new actions.AuthUserChange(action.user);
    })
  );

  /**
   * central call to change data in auth and profile stores
   */
  @Effect()
  authUser$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthUserChange),
    map((action: any) => {
      if (action.payload !== null) {
        if (action.payload.userProfile?.image) {
          const relativePath = action.payload.userProfile.image;
          const fullPath = `${this.configService.appImagesUrl}/${relativePath}`;
          console.log(fullPath);
          action.payload.userProfile.image = fullPath;
        }
      }
      this.authToken.user$.next(action.payload);

      return new actions.AuthTokenPayload(action.payload);
    })
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LogoutAction),
    switchMap((logoutPayload: SignOut) =>
      this.authService.logout().pipe(
        map(() => {
          return this.authToken.trancateLocalStorageDb();
        }),
        map(() => new actions.LoginRedirect('')),
        catchError((error: any) => of(new actions.AuthFailure(error)))
      )
    ));

  @Effect({ dispatch: false })
  googleSign$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.GoogleSign),
    tap((data: any) => {
      // auth
      //   .signInWithPopup(new fireAuth.GoogleAuthProvider())
      //   .catch(this.dispatchError);
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginRedirect),
    tap((data: any) => {
      // this.redirectUrl = data.payload || '';
      this.router.navigate([this.loginUrl]);
    })
  );

  @Effect({ dispatch: false })
  authRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthTokenPayload),
    filter(_ => this.router.url === this.loginUrl),
    tap((data: any) => {
      this.router.navigate([this.redirectUrl]);
    }),
    catchError((error: any) => of(new actions.AuthFailure(error)))
  );

  @Effect({ dispatch: true })
  refreshToken$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.TokenRefresh),
    tap(() => {
      console.log('Action [TokenRefresh] dispaching');
    })
  );

  @Effect({ dispatch: false })
  checkCurrentPath$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.ChangeUserSchoolSelection),
    map(((action: any) => action.payload)),
    switchMap((payload: UserSchool) => {

      this.authToken.selectedUserSchool$.next(payload);

      return this.authToken.selectedUserSchool$;
    }),
    // filter(_ => this.router.url === this.loginUrl),
    map((payload: any) => {
      this.router.navigate([this.redirectUrl]);
    }),
  );
}
