import { SchoolClass } from '.';

export interface ISelectable {
  disabled?: boolean;
  selected?: boolean;
  expand?: boolean;
}
export interface User extends ISelectable {
  id?: number;
  userprofileId?: any;
  email: string;
  password?: any;
  active: number;
  locale: string;
  userProfile: UserProfile;
  userRights?: UserRight[];
  userSchools?: UserSchool[];
  relatedStudents?: any[];
  userSchoolGroups?: UserSchoolGroup[];
  schoolClassGroups?: SchoolClassGroup[];
}

export interface UserSchool {
  school: School;
  userSchoolGrants: UserSchoolGrant[];
}

export interface UserSchoolGrant {
  userSchoolUserId: number;
  userSchoolSchoolId: number;
  grantedGroupId: number;
  grantedGroupName: string;
}

export interface UserRight {
  right: string;
}

export interface SignUpUser {
  title: string;
  salutation: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  mobileNr: string;
  PhoneNr: string;
}

export interface UserProfile {
  id?: number;
  salutation?: string;
  title?: string;
  firstname: string;
  lastname: string;
  image?: string;
  address?: string;
  mobileNr: string;
  alternateNr?: string;
}

export interface UserGroup extends ISelectable {
  groupId: number;
  name: string;
}

export interface School extends ISelectable {
  id?: number;
  districtId: number;
  municipality?: any;
  officialId: string;
  name: string;
  type?: SchoolType;
  category?: SchoolCategory;
  address: string;
  studentCount: number;
  tel: string;
  fax: string;
  email: string;
  location?: SchoolLocation;
}
export interface UserSchoolGroup extends ISelectable {
  id: number;
  name: string;
  description: string;
  schoolId: number;
  schoolYearId: number;
  schoolClassId: number;
  ownerId: number;
  activeFrom: Date;
  activeTo: Date;
  school: School;
  schoolYear: SchoolYear;
  schoolClass: SchoolClass;
  owner: User;
  members: UserSchoolGroupMember[];
}

export interface UserSchoolGroupMember extends ISelectable {
  id: number;
  schoolGroupRole: UserSchoolGroupRole;
  locked: boolean;
  joinedAt: Date;
  user: { id: number; firstname: string, lastname: string };

}

export interface SchoolGrpDiscussion extends ISelectable {
  id: string;
  title: string;
  content: string;
  authorId: number;
  authorFullName: string;
  commentsCount: number;
  likesCount: number;
  createdAt: Date;
  modifiedAt: Date;
  comments: any[];
}

export enum UserSchoolGroupRole {
  Unmapped = 'Unmapped',
  Administrator = 'Administrator',
  Parent = 'Parent',
  Student = 'Student',
  SchoolParentCouncil = 'SchoolParentCouncil',
  ClassParentsCouncil = 'ClassParentsCouncil',
  ClassParentsCouncilDeputy = 'ClassParentsCouncilDeputy',
  ClassParentsCouncilTreasurer = 'ClassParentsCouncilTreasurer'
}

export interface SchoolLocation {
  districtName: string;
  stateName: string;
  stateCode: string;
  countryName: string;
  countryCode: string;
}
export interface SchoolType {
  id?: number;
  typeName: string;
}

export interface SchoolCategory {
  id?: number;
  categoryName: string;
}

export interface State extends ISelectable {
  id?: number;
  name: string;
  shortName: string;
  coatOfArms?: any;
  countryId: number;
  country?: any;
}

export interface District extends ISelectable {
  id: number;
  name: string;
  town?: string;
  administrativeDistrict?: string;
  state?: State;
}

export interface Country extends ISelectable {
  id?: number;
  name: string;
  code: string;
  coatOfArms?: any;
  locale: string;
  flag?: any;
}

export interface SchoolYear {
  id?: number;
  value: string;
  countryId: number;
  stateId: number;
  schoolId: number;
  beginDate: Date;
  endDate: Date;
  current: number;
  state: State;
  country: Country;
}

export interface SchoolClassGroup extends ISelectable {
  id?: string;
  name: string;
  schoolId: number;
  schoolYearId: number;
  schoolClassId: number;
  createdByUserId: number;
  schoolClass?: SchoolClass;
  schoolYear?: SchoolYear;
  schoolClassGroupLinks?: SchoolClassGroupLink[];
  teachers?: Teacher[];
  students?: Student[];
  createdAt?: Date;
  modifiedAt?: Date;
}

export interface SchoolClassGroupLink extends ISelectable {

  schoolClassGroup: SchoolClassGroup;
  student: Student;
  parent: Parent;
  approvedByTeacher: Teacher;
  createdAt: Date;
  modifiedAt: Date;
  approvedAt: Date;

}

export interface Student extends ISelectable {
  studentId?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  schoolId: number;
  /** Linked School class group for the current school year */
  linkedSchoolClassGroup: SchoolClassGroupLink[];
}

export interface Teacher extends ISelectable {
  id: number;
  userId: number;
  title: string;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  schoolId: number;

  schoolClassGroups: SchoolClassGroup[];
}

export interface Parent extends ISelectable {
  id: number;
  userId: number;
  linkedSchoolClassGroups: SchoolClassGroupLink[];
  advisoryBoards: any[];
}

export interface SchoolAdmin {
  schoolId: number;
}


export const InitialUser: User = {
  id: 0,
  active: 1,
  locale: 'de-DE',
  email: null,
  relatedStudents: [],
  userProfile: null,
  userprofileId: null,
};

export const InitialSchoolYear: SchoolYear = {
  id: 0,
  beginDate: null,
  endDate: null,
  state: null,
  stateId: null,
  schoolId: null,
  value: null,
  country: null,
  countryId: null,
  current: 0
};

export const InitialUserSchoolGroupMember: UserSchoolGroupMember = {
  id: 0,
  joinedAt: new Date(),
  locked: false,
  schoolGroupRole: UserSchoolGroupRole.Administrator,
  user: { id: 0, firstname: 'null', lastname: 'null' },
  disabled: false,
  selected: false
};
