import { createAction, props } from '@ngrx/store';

// AuthUserChanged action
export const AUTH_USER_CHANGED = '[MANAGEMENT] ACTION_USER_RELOADED';
export const AuthUserChanged = createAction(
    AUTH_USER_CHANGED,
    props<{ payload: any }>()
);
export const AUTH_USER_SCHOOL_CHANGED = '[MANAGEMENT] ACTION_USER_SCHOOL_CHANGED';
export const AuthUserSchoolChanged = createAction(
    AUTH_USER_SCHOOL_CHANGED,
    props<{ payload: any }>()
);

export const REQUEST_USER_DATA_BY_SCHOOL_GRANTS = '[MANAGEMENT] ACTION_REQUEST_USER_DATA_BY_SCHOOL_GRANTS';
export const RequestDashboardData = createAction(
    REQUEST_USER_DATA_BY_SCHOOL_GRANTS,

);
