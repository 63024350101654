import { Injectable } from '@angular/core';
import { IAuthFacade } from './interfaces/auth-interface.facade';

import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/core/store/auth/auth.reducer';
import { LoggedOnce, TokenRestore, AuthInit, LogoutAction } from 'src/app/core/store/auth/auth.actions';
import { StorageService } from 'src/app/core/services';
@Injectable({ providedIn: 'root' })
export class AuthFacade implements IAuthFacade {

    constructor(private store: Store<AuthState>) { }
    getStoredToken() {
        throw new Error('Method not implemented.');
    }

    refreshToken() {
        this.store.dispatch(new this.refreshToken());
    }

    loggedOnce(value: boolean) {
        this.store.dispatch(new LoggedOnce(value));
    }

    tokenRestore(payload: any) {
        this.store.dispatch(new TokenRestore(payload));
    }

    authInit() {
        this.store.dispatch(new AuthInit());
    }

    login(username: string, password: string, loggedOnce: boolean) {
        throw new Error('Method not implemented.');
    }
    logout() {
        this.store.dispatch(new LogoutAction());
    }

}
