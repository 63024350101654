import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AdvisoryActionTypes } from './advisory.actions';

import * as actions from './advisory.actions';
import { AdvisoryService } from '../../services/advisory.service';

// import all requried services or any dependencies

@Injectable()
export class AdvisoryEffeccts {

    constructor(
        private action$: Actions,
        private advissoryService: AdvisoryService) { }

    @Effect()
    load$ = this.action$.pipe(
        ofType(AdvisoryActionTypes.LOAD_ADVISORY),
        switchMap((action: any) => {
            return this.advissoryService.getbyClassAndSchoolYear(action.payload)
                .pipe(
                    map((response: any) => {
                        return new actions.LoadAdvisoryBoardSuccess(response.data);
                    }),
                    catchError(error => of(new actions.LoadAdvisoryBoardFailed(error)))
                    // dispatch action with payload in `map`
                    // dispatch action with error in `catchError`
                );
        })
    );
}
