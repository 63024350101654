import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Country } from 'src/app/libs/shared/models/user.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/libs/shared/models';

@Injectable({ providedIn: 'root' })
export class CountryService {

    constructor(private httpClient: HttpClient, private config: ConfigService) { }


    getAllCountriesMinified() {

        const url = `${this.config.baseUrl}/Locations/Countries/minified`;

        return this.httpClient.get<ApiResponse<Country[]>>(url).pipe(
            map(response => response.data)
        );
    }
}
