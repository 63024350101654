import { Action, createSelector, createFeatureSelector } from '@ngrx/store';

import { RegisterationState } from '.';
import {
  State,
  District,
  School,
  User
} from 'src/app/libs/shared/models';

export enum RegisterActionTypes {
  initState = '[REGISTER] Init State',
  loadStates = '[REGISTER] Load States',
  loadStatesSuccess = '[REGISTER] Load States success',
  selectedState = '[REGISTER] States selected',
  loadDistricts = '[REGISTER] Load Districts',
  loadDistrictsSuccess = '[REGISTER] Load Districts success',
  selectedDistrict = '[REGISTER] District selected',
  loadSchools = '[REGISTER] Load Schools',
  loadSchoolsSuccess = '[REGISTER] Load Schools success',
  schoolSelected = '[REGISTER] School selected',
  registerUser = '[REGISTER] register',
  registerUserSuccess = '[REGISTER] register success',
  registerFailed = '[REGISTER] register failed'
}

export class InitStateAction implements Action {
  readonly type = RegisterActionTypes.initState;
  constructor() {
    console.log('init state ');
  }
}

/**
 * Get States by Country ID
 */
export class LaodStatesAction implements Action {
  readonly type = RegisterActionTypes.loadStates;
  /**
   *
   * @param payload : country ID
   */
  constructor(readonly payload: number) { }
}
export class LaodStatesSuccessAction implements Action {
  readonly type = RegisterActionTypes.loadStatesSuccess;
  /**
   *
   * @param payload : country ID
   */
  constructor(readonly payload: State[]) { }
}
export class StateSelectedAction implements Action {
  readonly type = RegisterActionTypes.selectedState;
  /**
   *
   * @param payload : state ID
   */
  constructor(readonly payload: number) { }
}

/**
 * Get Districts by state ID
 */
export class LoadDistrictsAction implements Action {
  readonly type = RegisterActionTypes.loadDistricts;
  /**
   * @param playload : The state id
   */
  constructor(readonly playload: number) { }
}

export class LoadDistrictsSuccessAction implements Action {
  readonly type = RegisterActionTypes.loadDistrictsSuccess;
  /**
   * @param playload : The state id
   */
  constructor(readonly playload: District[]) { }
}
/**
 * Get Districts by state ID
 */
export class DistrictSelectedAction implements Action {
  readonly type = RegisterActionTypes.selectedDistrict;
  /**
   * @param playload : The state id
   */
  constructor(readonly playload: boolean) { }
}

export class LoadSchoolsAction implements Action {
  readonly type = RegisterActionTypes.loadSchools;
  constructor(readonly payload: number) { }
}

export class LoadSchoolsSuccessAction implements Action {
  readonly type = RegisterActionTypes.loadSchoolsSuccess;
  constructor(readonly payload: School[]) { }
}
export class SchoolSelectedAction implements Action {
  readonly type = RegisterActionTypes.schoolSelected;
  /**
   * @param payload: the selected school's id
   */
  constructor(readonly payload: number) { }
}

export class RegisterUserAction implements Action {
  readonly type = RegisterActionTypes.registerUser;
  constructor(readonly payload: User) { }
}

export class RegisterUserSuccessAction implements Action {
  readonly type = RegisterActionTypes.registerUserSuccess;
  constructor(readonly payload: User) { }
}
export class RegisterUserFailed implements Action {
  readonly type = RegisterActionTypes.registerFailed;
  constructor(readonly payload: any) { }
}

export type RegisterActions =
  | InitStateAction
  | LaodStatesAction
  | LaodStatesSuccessAction
  | StateSelectedAction
  | LoadDistrictsAction
  | LoadDistrictsSuccessAction
  | DistrictSelectedAction
  | LoadSchoolsAction
  | LoadSchoolsSuccessAction
  | SchoolSelectedAction
  | RegisterUserAction
  | RegisterUserSuccessAction
  | RegisterUserFailed;

export const getRegistrationState = createFeatureSelector<RegisterationState>(
  'registerUser'
);

export const getStates = createSelector(
  getRegistrationState,
  (state: RegisterationState) => {
    return state.states;
  }
);

export const getStatesLoading = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.loadingStates
);

export const getdistricts = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.districts
);

export const getDistrictsLoading = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.loadingDistricts
);

export const getSchools = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.schools
);

export const getSchoolsLoading = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.loadingSchools
);

export const getuser = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.user
);

export const getError = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.error
);

export const getloading = createSelector(
  getRegistrationState,
  (state: RegisterationState) => state.loading
);

export const isUserRegistred = createSelector(
  getRegistrationState,
  (state: RegisterationState) => {
    return state.user !== null && state.user.id > 0;
  }
);
