import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { AuthFacade } from 'src/app/libs/facades/auth.facade';

const USER_TOKEN = 'token';

const USER = 'user';

const CURRENT_USER_SCHOOL = 'currentUserSchool';

const USER_LOGGED_ONCE = 'logged_once';

@Injectable({ providedIn: 'root' })
export class AuthTokenService {


  public token$ = new BehaviorSubject(null);
  public user$ = new BehaviorSubject(null);
  public selectedUserSchool$ = new BehaviorSubject(null);

  constructor(
    public storage: StorageService,
    public authFacade: AuthFacade
  ) { }

  load(): Promise<any> {

    return new Promise((resolve, reject) => {

      this.authFacade.authInit();

      Promise.all([this.storage.get(USER_TOKEN), this.storage.get(CURRENT_USER_SCHOOL)]).then(
        (values) => {
          let token = values[0] || null;
          const selectedUserSchool = values[1] || null;

          this.token$.next(token);
          this.storage.get(USER).then((user: any) => {
            if (!user && token) {
              this.trancateLocalStorageDb();
              token = null;
              user = null;
            }

            if (!!token && !!user) {
              try {
                const payload = {
                  token,
                  user,
                  selectedUserSchool
                };
                this.authFacade.tokenRestore(payload);

              } catch (error) {
                token = null;
              }
            }

            this.token = token;
            this.user = user;
            this.SelectedUserSchool = selectedUserSchool;

            this.token$
              .pipe(switchMap(this.dumpToken), switchMap(this.updateLoggedOnce))
              .subscribe(() => { });
            resolve(token);

            this.user$
              .pipe(
                switchMap(this.dumpUser)
              ).subscribe(() => { });
            resolve(user);

            this.selectedUserSchool$.pipe(
              switchMap(this.dumpSelectedSchool)
            ).subscribe(() => { });
            resolve(selectedUserSchool);
          });

        },
        error => {
          resolve(null);
        }
      );
    });
  }

  trancateLocalStorageDb() {
    console.log('truncate local DB');

    return this.storage.set(USER_TOKEN, '').then(values => {
      this.storage.set(USER, '');
    }).catch(error => {
      console.log('Error Trancating local Storage');
    });
  }

  dumpToken = token => {
    return !!token
      ? this.storage.set(USER_TOKEN, token)
      : this.storage.remove(USER_TOKEN).then(() => null);
  }

  dumpUser = user => {
    return this.storage.set(USER, user);
  }

  dumpSelectedSchool = selectedSchool => {
    return this.storage.set(CURRENT_USER_SCHOOL, selectedSchool);
  }

  updateLoggedOnce = token => {
    return this.storage.get(USER_LOGGED_ONCE).then(loggedOnce => {
      if (token || loggedOnce) {
        this.authFacade.loggedOnce(true);
        return loggedOnce
          ? token
          : this.storage.set(USER_LOGGED_ONCE, Date.now()).then(_ => token);
      } else {
        return Promise.resolve(token);
      }
    });
  }

  set token(value) {
    this.token$.next(value);
  }

  set user(value) {
    this.user$.next(value);
  }

  set SelectedUserSchool(value) {
    this.selectedUserSchool$.next(value);
  }
  // tslint:disable-next-line:adjacent-overload-signatures
  get token() {
    return this.token$.value;
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get user() {
    return this.user$.value;
  }
  // tslint:disable-next-line:adjacent-overload-signatures
  get SelectedUserSchool() {
    return this.selectedUserSchool$.value;
  }


  readPayload(token) {
    const payload = this.getTokenPayload(token);

    return payload;
  }

  getTokenPayload(token) {

    const tokenPayload = token
      ? JSON.parse(this.b64DecodeUnicode(token.split('.')[1]))
      : null;

    return tokenPayload;
  }

  b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    const res = decodeURIComponent(
      atob(str)
        .split('')
        // tslint:disable-next-line:only-arrow-functions
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return res;
  }
}

// tslint:disable-next-line:ban-types
export function AuthTokenFactory(service: AuthTokenService): Function {
  return () => service.load();
}
