import { AdvisoryAction, AdvisoryActionTypes } from "./advisory.actions";

//import or declare state
export interface ResponseModel {
    schoolclass: any,
    schoolyear: any,
    advisor_ceo: any,
    advisor_deputy: any,
    treasurer: any,
    created_at: Date,
    updated_at: Date
}
export interface RequestParams {
    schoolclass_id,
    schoolyear_id
}
export interface AdvisoryState {
    loading: boolean,
    error?: any,
    request_params?: RequestParams,
    response_model: Array<ResponseModel>
}

export const intialState: AdvisoryState = {
    loading: false,
    error: null,
    request_params: null,
    response_model: new Array<ResponseModel>()
};
export function AdvisoryReducer(state = intialState, action: AdvisoryAction) {
    switch (action.type) {
        case AdvisoryActionTypes.LOAD_ADVISORY: {
            //add your code
            return {
                ...state,
                request_params: action.payload,
                loading: true
            };
        }
        case AdvisoryActionTypes.ADVISORY_LOADED_SUCESS:

            return {
                ...state,
                response_model: [...state.response_model, action.payload],
                request_params: null,
                //response_model: state.response_model.concat(action.payload),
                loading: false

            }
        case AdvisoryActionTypes.ADVISORY_LOADED_FAILED:

            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}