import { createAction, props } from '@ngrx/store';
import { UserSchoolGroup } from 'src/app/libs/shared/models/user.model';

export const LAOD_SCHOOL_GROUP_DETAILS = '[SCHOOL GROUP] LAOD SCHOOL GROUP DETAILS';
export const GetSchoolGroupDetails = createAction(
    LAOD_SCHOOL_GROUP_DETAILS, props<{ id: number }>()
);

export const LAOD_SCHOOL_GROUP_DETAILS_SUCCESS = '[SCHOOL GROUP] LAOD SCHOOL GROUP DETAILS SUCCESS';
export const GetSchoolGroupDetailsSuccess = createAction(
    LAOD_SCHOOL_GROUP_DETAILS_SUCCESS, props<{ data: UserSchoolGroup }>()
);

export const LAOD_SCHOOL_GROUP_DETAILS_FAILED = '[SCHOOL GROUP] LAOD SCHOOL GROUP DETAILS FAILED';
export const GetSchoolGroupDetailsFailed = createAction(
    LAOD_SCHOOL_GROUP_DETAILS_FAILED, props<{ error: Error }>()
);
