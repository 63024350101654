export { DataPage } from './page.model';
export { publicHoliday } from './public-holiday.model';
export { ApiResponse } from './api-response.model';
export { SchoolClass } from './school-class.model';
export { Student } from './user.model';
export { State } from './state.model';
// xport { District } from './Disctrict.model';
export { AuthToken } from './auth-response.model';
export { SignOut } from './auth.signOut.model';
export { User, SchoolYear, InitialSchoolYear, School, District, SignUpUser, UserSchoolGroup, SchoolGrpDiscussion } from './user.model';

export { AdminDashboardContent } from './admin-dashboard-content.model';
export { ParentDashboardContent } from './parent-dashboard-content.model';
export { StudentDashboardContent } from './student-dashboard-content.model';
export { SuperAdminDashboardContent } from './sa-admin-dashboard-conten.model';

export { SchoolGroupName } from './school-group-name.model';
export { SchoolClassGroupInfo } from './school-class-group-info.model';
