import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

import { catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  token: string;
  locale = 'en-US';
  constructor(
    private configService: ConfigService,
    public authToken: AuthTokenService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private router: Router,
  ) {
    authToken.token$.subscribe(token => {
      this.token = token;
    });
    authToken.user$.subscribe(user => {
      if (user) {
        this.locale = user.locale || 'en-US';
      }
    });
    // authFacade.refreshToken();

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    

    request.headers.set('Content-Type', 'application/json');
    
    if (request.url.search(this.configService.baseUrl) === 0) {
      // attach token
      const handle = this.handleApiRequest(request, next);
      return handle;
    } else {
      return next.handle(request);
    }
  }



  handleApiRequest(request, next): Observable<HttpEvent<any>> {
    // replace it with a auth store selector when refresh token is working    
    request = this.token
      ? request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          'Accept-Language': this.locale          
        }
      })
      : request;

    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        // 403 Der Zugriff ist dauerhaft verboten und liegt an der Anwendungslogik, wie etwa unzureichenden Rechten für eine Ressource.
        // 401 Unauthorized gibt an, dass der Server die Anfrage aufgrund fehlender oder ungültiger Authentifizierung abgelehnt hat.
        if (error.status === 401 || error.status === 403) {

          if (error.status === 401) {
            console.log(error);
            // this.notificationService.warning(
            //   this.translate.instant('errors.unauthorized'),
            //   this.translate.instant('errors.session_timed_out'));
            // this.authToken.authFacade.logout();
            this.authToken.trancateLocalStorageDb().then(() => {
              this.router.navigate(['/auth/login']);
            });

            return throwError(error);
          } else {
            // this.notificationService.warning(
            //   this.translate.instant('errors.access_forbidden'),
            //   'errors.error_403');
            // this.authToken.authFacade.logout();
            return throwError(error);
          }
          // return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
}
