
import { User } from 'src/app/libs/shared/models';
import { map, take, reduce } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import * as fromNav from '../../store/navigation';
import * as fromRoute from '../../store/router';
import { Store, select } from '@ngrx/store';
import { selectResult, selectNavigationItems } from '../../store/navigation';
import { getRouterState } from '../../store/router';
import { RouterReducerState } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class PermissionsService {

    constructor(
        private navigationStore: Store<fromNav.NavigationState>,
        private routeStore: Store<fromRoute.RouterStateUrl>
    ) {

        // this.navigationStore.select(selectResult).subscribe(se => {
        //     console.log(se);
        // });

        // this.navigationStore.select(selectNavigationItems).subscribe(nav => {
        //     console.log(nav);
        // });

    }

    canNavigate(user: User): Observable<boolean> {

        console.log('can activate child permission');

        return this.navigationStore.pipe(
            select(selectResult),
            map(result => {
                console.log(result);
                return result.active;
            })
        );
    }
}
