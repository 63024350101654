import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { DataPage } from 'src/app/libs/shared/models';
import { Teacher } from 'src/app/libs/shared/models/user.model';
import { Observable } from 'rxjs';
import { AdminCreateTeacherRequest } from 'src/app/libs/shared/models/admin-create-teachter.request';

@Injectable()
export class TeacherService {



    constructor(private httpClient: HttpClient, private config: ConfigService) { }

    getPagedTeacherList(schoolId: number, page: number, pageSize: number): Observable<DataPage<Teacher>> {

        // users/Teachers/1/10/BySchool/1
        const url = `${this.config.baseUrl}/Users/Teachers/${page}/${pageSize}/BySchool/${schoolId}`;

        return this.httpClient.get<DataPage<Teacher>>(url);
    }
    createTeacher(request: AdminCreateTeacherRequest) {

        const url = `${this.config.baseUrl}/Users/Teachers`;

        return this.httpClient.post<any>(url, request);

    }

    DeleteTeacher(id: number) {
        const url = `${this.config.baseUrl}/Users/Teachers/${id}`;
        return this.httpClient.delete(url);
    }

}
