import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SchoolGrouActions from './school-group.actions';
import { SchoolService } from '../../services/school.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class SchoolGroupEffects {

    effectName$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SchoolGrouActions.LAOD_SCHOOL_GROUP_DETAILS),
            switchMap((payload: any) =>
                this.schollService.getSchoolGroupDetails(payload.id)
                    .pipe(
                        map(data => SchoolGrouActions.GetSchoolGroupDetailsSuccess({ data })),
                        catchError(error => of(SchoolGrouActions.GetSchoolGroupDetailsFailed({ error }))))
            ),
        );
    });


    constructor(
        private actions$: Actions, private schollService: SchoolService) { }
}
