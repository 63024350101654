import { createReducer, Action, on } from '@ngrx/store';
import { Country, State, District, School } from 'src/app/libs/shared/models/user.model';
import * as LocationActions from './location.actions';
import { ApiResponse, DataPage } from 'src/app/libs/shared/models';

export interface LocationFilter {
    loadedCountries?: Country[];
    loadedStates?: State[];
    loadedTownNames?: string[];
    loading: boolean;
    error?: any;
}
export interface FilteredSchoolResultSet {
    filtredSchools?: DataPage<any>;
    loading: boolean;
    error?: any;
}

export const initialSchoolResultSet: FilteredSchoolResultSet = {
    loading: false,
    error: null,
    filtredSchools: null,
};
export interface LocationState {
    locationFilter: LocationFilter;
    filtredSchoolsRes?: FilteredSchoolResultSet;

}

export const InitialLocationFilter: LocationFilter = {
    loading: false,
    error: null,
    loadedCountries: new Array(),
    loadedTownNames: new Array(),
    loadedStates: new Array(),
};

export const initialState: LocationState = {
    locationFilter: InitialLocationFilter,
    filtredSchoolsRes: initialSchoolResultSet
};

const LocationReducer = createReducer(
    initialState,
    on(LocationActions.LoadCountries, locaionstate => {
        return ({
            ...locaionstate,
            locationFilter: setLoading(locaionstate.locationFilter, true)
        });
    }),
    on(LocationActions.LoadCountriesSuccess, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setLoadedCountries(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadCountriesFailed, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setError(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadStatesByCountry, locationstate => {
        return {
            ...locationstate,
            locationFilter: setLoading(locationstate.locationFilter, true)
        };
    }),
    on(LocationActions.LoadStatesByCountrySuccess, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setfilteredStates(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadStatesByCountryFailed, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setError(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadDistrictsByState, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setLoading(locationstate.locationFilter, true)
        };
    }),
    on(LocationActions.LoadDistrictsByStateSuccess, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setfilteredDistricts(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadDistrictsByStateFailed, (locationstate, action) => {
        return {
            ...locationstate,
            locationFilter: setError(locationstate.locationFilter, action)
        };
    }),
    on(LocationActions.LoadSchoolListByFilter, (locationstate, action) => {
        return {
            ...locationstate,
            filtredSchoolsRes: initSchoolRes()
        };
    }),
    on(LocationActions.LoadSchoolListByFilterSuccess, (locationstate, action) => {
        return {
            ...locationstate,
            filtredSchoolsRes: setFilteredSchoolResultSet(locationstate.filtredSchoolsRes, action)
        };
    }),
    on(LocationActions.LoadSchoolListByFilterFailed, (locationstate, action) => {
        return {
            ...locationstate,
            filtredSchoolsRes: setFiltredSchoolsErrorRes(locationstate.filtredSchoolsRes, action)
        };
    })

);

export function reducer(locaionstate: LocationState, action: Action) {
    return LocationReducer(locaionstate, action);
}

function setLoading(locationfilter: LocationFilter, value: boolean) {
    locationfilter.loading = value;

    return locationfilter;
}

function setLoadedCountries(locationfilter: LocationFilter, action: any) {
    locationfilter.loadedCountries = action.payload;
    locationfilter.loading = false;
    return locationfilter;
}

function setError(locationfilter: LocationFilter, action: any) {
    const locationFilter: LocationFilter = {
        loading: false,
        error: action.payload
    };
    return locationFilter;
}

function setfilteredStates(locationfilter: LocationFilter, action: any) {
    locationfilter.loadedStates = action.payload;
    locationfilter.loading = false;

    return locationfilter;
}

function setfilteredDistricts(locationfilter: LocationFilter, action: any) {
    locationfilter.loading = false;
    locationfilter.loadedTownNames = action.payload;
    return locationfilter;
}

function initSchoolRes() {
    const res: FilteredSchoolResultSet = {
        loading: true,
        error: null,
        filtredSchools: null,

    };
    return res;
}

function setFilteredSchoolResultSet(filtredSchoolsRes: FilteredSchoolResultSet, action: any) {

    filtredSchoolsRes.filtredSchools = action.payload;
    console.log(filtredSchoolsRes);
    filtredSchoolsRes.loading = false;

    return filtredSchoolsRes;
}

function setFiltredSchoolsErrorRes(filtredSchoolsRes: FilteredSchoolResultSet, action: any) {
    filtredSchoolsRes.error = action.payload;
    filtredSchoolsRes.loading = false;

    return filtredSchoolsRes;
}
