import { ProfileActions, ProfileActionTypes } from './profile.actions';
import * as fromAuth from '../auth';
import { Profile, DefaultProfile, createProfile, createProfileUpdateState } from './profile.model';
import { User } from 'src/app/libs/shared/models';
export interface ProfileState {
  loading: boolean;
  model: Profile;
  error: any;
  user: User;
  profileUpdateState?: ProfileUpdateState;
}

export interface ProfileUpdateState {
  loading: boolean;
  error: any;
}

export const initialProfileState: ProfileState = {
  loading: true,
  model: { ...DefaultProfile },
  error: null,
  user: null,
  profileUpdateState: createProfileUpdateState(false, null),
};

export function profileReducer(
  state = initialProfileState,
  action: ProfileActions | fromAuth.AuthActions
): ProfileState {
  switch (action.type) {
    case fromAuth.AuthActionTypes.AuthTokenPayload:
      // console.log('fromAuth.AuthActionTypes.AuthTokenPayload');
      return {
        ...state,
        model: createProfile(action.payload),
        user: action.payload
      };

    case ProfileActionTypes.USERPROFILE_RESTORE:
      // console.log('ProfileActionTypes.USERPROFILE_RESTORE');
      return {
        ...state,
        loading: false,
        model: createProfile(action.payload),
        user: action.payload
      };

    case ProfileActionTypes.PROFILE_UPDATE:
      return {
        ...state,
        loading: true,
        model: { ...action.payload },
      };

    case ProfileActionTypes.PROFILE_UPDATE_USER:
      return {
        ...state,
        loading: true,
        user: action.user,
      };

    case ProfileActionTypes.USERPROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case ProfileActionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        error: null,
        profileUpdateState: createProfileUpdateState(true, null)
      };

    case ProfileActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      // console.log('ProfileActionTypes.UPDATE_USER_PROFILE_SUCCESS');
      return {
        ...state,
        model: createProfile(action.user),
        user: action.user,
        profileUpdateState: createProfileUpdateState(false, null)
      };
    case ProfileActionTypes.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        profileUpdateState: createProfileUpdateState(false, action.error)
      };



    case ProfileActionTypes.USERPROFILE_UPDATE_FAILED:
    case ProfileActionTypes.SCHOOL_LIST_UPDATE_FAILED:
    case ProfileActionTypes.STUDENT_LIST_UPDATE_FAILED:
    case ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS:
      const newEmptyState = { ...state, loading: true };
      if (action.payload.studentId) {
        newEmptyState.user.relatedStudents.filter(
          stud => stud.studentId === action.payload.studentId
        )[0].teachers = [];
        return {
          ...newEmptyState,
          loading: true
        };
      }
    // tslint:disable-next-line:no-switch-case-fall-through
    case ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS_SUCCESS:
      const newState = { ...state, loading: false };
      if (action.payload.studentId) {
        newState.user.relatedStudents.filter(
          stud => stud.studentId === action.payload.studentId
        )[0].teachers = action.payload.teachers;
        return {
          ...newState,
          loading: false
        };
      }
      return {
        ...state
      };

    // tslint:disable-next-line:no-switch-case-fall-through
    case ProfileActionTypes.UPDATE_SCHOOLS_LIST:
      return { ...state };

    case ProfileActionTypes.UPDATE_STUDENT_LIST:
      return { ...state };

    default:
      return state;
  }
}
