export enum UserGroup {
    Unmapped = 'None',
    Owners = 'Owners',
    All = 'All',
    Users = 'Users',
    SA = 'SA',
    Admin = 'Administrators',
    Teachers = 'Teachers',
    Students = 'Students',
    Parents = 'Parents',
}
