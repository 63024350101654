import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './libs/layout/main/main.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthComponent } from './features/auth/login/auth.component';
import { LayoutModule } from './libs/layout/layout.module';
import { UserAuthorizationGuard } from './core/guards/Authorization.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [UserAuthorizationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/home.module').then(m => m.HomeModule)
      },
      {
        path: 'management',
        loadChildren: () =>
          import('./features/management/management.module').then(
            m => m.ManagementModule
          )
      },
      {
        path: 'miscellaneous',
        loadChildren: () =>
          import('./features/miscellaneous/miscellaneous.module').then(
            m => m.MiscellaneousModule
          ),
        data: { pageTitle: 'Miscellaneous' }
      }
    ]
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/dashboard/home.module').then(m => m.HomeModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  { path: '**', redirectTo: 'miscellaneous/error404' }
];

@NgModule({
  imports: [
    LayoutModule,
    RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
