import { NgModule, ModuleWithProviders, APP_INITIALIZER, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IonicStorageModule } from '@ionic/storage';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import * as fromStore from './store';
import { AuthGuard } from './guards/auth.guard';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { ConfigFactory, ConfigService } from './services/config.service';
import { RefreshService } from './services/refresh-data.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { services, AuthTokenFactory, AuthTokenService, TokenInterceptor } from './services';
import { ToastrModule } from 'ngx-toastr';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '../store/router';
import { UserAuthorizationGuard } from './guards/Authorization.guard';
import { PermissionsService } from './guards/auth.persmissions';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
const iconClasses = {
  error: 'fal fa-times-circle fa-2x',
  info: 'fal fa-info-circle',
  success: 'fal fa-thumbs-up',
  warning: 'fal fa-exclamation-circle'
};

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NzNotificationModule,
    ToastrModule.forRoot({
      // timeOut: 300000000,
    }),
    IonicStorageModule.forRoot({
      name: '__shulhof',
      driverOrder: ['sqlite', 'indexeddb', 'websql']
    }),
    // HotkeysModule.forRoot(),

    StoreModule.forRoot(fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([...fromStore.effects, AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, logOnly: environment.production,
      actionsBlocklist: ['@ngrx/router*']
    }),

    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),

    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  exports: [],
  providers: [
    AuthGuard,
    UserAuthorizationGuard,
    PermissionsService,
    NzNotificationService,
    ...services,
    ...fromStore.services,

    {
      provide: APP_INITIALIZER,
      useFactory: AuthTokenFactory,
      deps: [AuthTokenService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigFactory,
      deps: [ConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    RefreshService,
    { provide: LOCALE_ID, useValue: 'de' } // remove it and replace with dynamic Locale
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}
