import { RegisterActionTypes, RegisterActions } from './register.actions';
import {
  State,
  District,
  School,
  User
} from 'src/app/libs/shared/models';

export interface RegisterationState {
  country_id: number;
  loading: boolean;
  loadingStates: boolean;
  loadingDistricts: boolean;
  loadingSchools: boolean;
  stateSelected: boolean;
  districtSelected: boolean;
  schoolSelected: boolean;
  error?: any;
  states: Array<State>;
  districts: Array<District>;
  schools: Array<School>;
  user: User;
}

export const initialState: RegisterationState = {
  country_id: 0,
  loading: false,
  loadingStates: false,
  loadingDistricts: false,
  loadingSchools: false,
  stateSelected: false,
  districtSelected: false,
  schoolSelected: false,
  error: null,
  user: null,
  states: new Array<State>(),
  districts: new Array<District>(),
  schools: new Array<School>()
};

export function reducer(
  state = initialState,
  action: RegisterActions
): RegisterationState {
  switch (action.type) {
    case RegisterActionTypes.loadStates:
      return {
        ...state,
        country_id: action.payload,
        loading: false,
        error: null,
        // tslint:disable-next-line:new-parens
        states: new Array(),
        districtSelected: false,
        districts: new Array(),
        loadingDistricts: false,
        loadingSchools: false,
        loadingStates: true,
        schoolSelected: false,
        schools: new Array(),
        stateSelected: false,
        user: null
      };
    case RegisterActionTypes.loadStatesSuccess:
      return {
        ...state,
        loading: false,
        states: action.payload,
        loadingStates: false
      };
    case RegisterActionTypes.loadDistricts:
      return {
        ...state,
        loading: true,
        loadingDistricts: true
      };
    case RegisterActionTypes.loadDistrictsSuccess:
      return {
        ...state,
        loading: false,
        districts: action.playload,
        loadingDistricts: false,
        error: null,
        user: null
      };
    case RegisterActionTypes.loadSchools:
      return {
        ...state,
        loadingSchools: true,
        loading: true
      };
    case RegisterActionTypes.loadSchoolsSuccess:
      return {
        ...state,
        schools: action.payload,
        loadingSchools: false,
        loading: false
      };
    case RegisterActionTypes.registerUser:
      return {
        ...state,
        user: action.payload,
        loading: true,
        error: null
      };
    case RegisterActionTypes.registerUserSuccess:
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload.id
        },
        loading: false
      };
    case RegisterActionTypes.registerFailed:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case RegisterActionTypes.initState:
      return {
        ...state,
        user: null
      };
    default:
      return state;
  }
}
