import { User } from 'src/app/libs/shared/models';
import { ProfileUpdateState } from '.';

export interface Profile {
  id: number;
  name: string;
  fullName: string;
  pic: string;
  hashTag: string;
}

export const DefaultProfile: Profile = {
  id: null,
  name: null,
  fullName: null,
  pic: 'https://i.imgur.com/0ROzKWN.png',
  hashTag: null
};

export function createProfile(data: User): Profile {
  return {
    id: data.id || DefaultProfile.id,
    name: data.userProfile.firstname || DefaultProfile.name,
    fullName: `${data.userProfile.firstname}, ${data.userProfile.lastname}` || DefaultProfile.fullName,
    pic: data.userProfile.image || DefaultProfile.pic,
    hashTag: `@${data.userProfile.firstname.replace(/\s/g, '')}${data.userProfile.lastname.replace(/\s/g, '')}` || DefaultProfile.hashTag || '#'
  };
}

export function createProfileUpdateState(loading: boolean, error: any): ProfileUpdateState {
  return { loading, error };
}




