import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { DataPage, SchoolGrpDiscussion } from 'src/app/libs/shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SchoolGroupService {

    constructor(private httpClient: HttpClient, private config: ConfigService) { }

    getPostsByPage(schoolGrpId: number, pageNr: number, pageSize: number): Observable<DataPage<any>> {

        const url = `${this.config.baseUrl}/UserSchoolGroups/Posts/${schoolGrpId}/${pageNr}/${pageSize}`;

        return this.httpClient.get<DataPage<any>>(url);
    }

    createPost(title: string, content: string, schoolGrpId: number): Observable<SchoolGrpDiscussion> {

        const url = `${this.config.baseUrl}/UserSchoolGroups/Posts`;

        return this.httpClient.post<SchoolGrpDiscussion>(url, { title, content, schoolGrpId });
    }

    postComment(comment: string, postId: string, commentParentId: string) {

        const url = `${this.config.baseUrl}/UserSchoolGroups/Posts/Comments`;

        return this.httpClient.post<SchoolGrpDiscussion>(url, { postId, commentParentId, comment });

    }


}
