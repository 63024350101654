import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPage, Student } from 'src/app/libs/shared/models';
import { ConfigService } from './config.service';

@Injectable()
export class StudentService {


    constructor(private httpClient: HttpClient, private config: ConfigService) {

    }


    createStudent(student: { schoolId: number; firstName: any; lastName: any; dateOfBirth: Date; }) {

        const url = `${this.config.baseUrl}/Students`;

        return this.httpClient.post<any>(url, student);
    }

    getPagedStudentList(page: number, size: number, schoolId: number): Observable<DataPage<Student>> {
        const url = `${this.config.baseUrl}/Students/${page}/${size}/BySchool/${schoolId}`;

        return this.httpClient.get<DataPage<Student>>(url);
    }



}
