import { Injectable } from '@angular/core';

import { BehaviorSubject, of } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataPage, User, ApiResponse } from 'src/app/libs/shared/models';
import { map } from 'rxjs/operators';

const defaultUser = {
  username: 'Zied Mayel'
};

@Injectable()
export class UserService {



  public user$ = new BehaviorSubject({ ...defaultUser });

  constructor(private httpClient: HttpClient, private config: ConfigService) { }

  // tslint:disable-next-line:variable-name
  getUsersByPage(current_page: number, offset: number) {
    const url = `${this.config.baseUrl}/users/getByPage?page=${current_page}&per_page=${offset}`;

    return this.httpClient.get<DataPage<User>>(url);
  }

  getUsersByPageAndSchoolId(schoolId: number, page: number, pageSize: number) {

    const url = `${this.config.baseUrl}/Users/BySchool/${schoolId}/${page}/${pageSize}`;

    return this.httpClient.get<DataPage<User>>(url);
  }

  // tslint:disable-next-line:variable-name
  getUserById(user_id: any) {
    const url = `${this.config.baseUrl}/Users/${user_id}`;

    return this.httpClient.get<any>(url);
  }
  joinschool(userId: number, schoolId: number, ignoreIdentity: boolean) {
    const url = `${this.config.baseUrl}/users/JoinSchool`;

    return this.httpClient.put<ApiResponse<User>>(url, { userId, schoolId, ignoreIdentity }).pipe(
      map((res: ApiResponse<User>) => res)
    );
  }
  disjoinschool(userId: number, schoolId: number, ignoreIdentity: boolean) {
    const url = `${this.config.baseUrl}/users/JoinSchool`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: { userId, schoolId, ignoreIdentity }
    };

    return this.httpClient.delete<ApiResponse<User>>(url, httpOptions)
      .pipe(
        map((res: ApiResponse<User>) => res)
      );
  }

  getTeachers(schoolClassId: number, schoolYearId: number) {
    const url = `${this.config.baseUrl}/Users/Teachers/SchoolClasses/${schoolClassId}/SchoolYears/${schoolYearId}`;
    return this.httpClient.get<any>(url);
  }

  delete(id: any) {
    const url = `${this.config.baseUrl}/Users/${id}`;
    return this.httpClient.delete(url);
  }
  updateUserProfile(profileData: any, userId: number) {
    const url = `${this.config.baseUrl}/Users/UpdateProfile/${userId}`;

    return this.httpClient.patch(url, profileData);
  }

}
