import { Action, createReducer, on } from '@ngrx/store';
import {
    AdminDashboardContent,
    ParentDashboardContent,
    School,
    StudentDashboardContent,
    SuperAdminDashboardContent,
    User
} from 'src/app/libs/shared/models';
import * as managementActions from './management.actions';
import * as authActions from '../auth/auth.actions';

export interface ManagementState {
    selectedSchool?: School;
    currentUser: User;
    dashboard: AdminDashboardContent | ParentDashboardContent | StudentDashboardContent | SuperAdminDashboardContent;
}

export const initialState: ManagementState = {
    selectedSchool: null,
    currentUser: null,
    dashboard: null
};



const ManagementReducer = createReducer(
    initialState,
    on(managementActions.AuthUserChanged, (managementState, action) => {
        console.log(action);
        return {
            ...managementState,
            // tslint:disable-next-line: no-non-null-assertion
            selectedSchool: action.payload.selectedUserSchool || null,
            currentUser: action.payload.user
        };
    }),
    on(managementActions.AuthUserSchoolChanged, (managementState, action) => {
        console.log(action);
        return {
            ...managementState,
            // tslint:disable-next-line: no-non-null-assertion
            selectedSchool: action.payload || null,
        };
    }),
);
export function reducer(state: ManagementState, action: Action) {
    return ManagementReducer(state, action);
}

function setSchool(action: any) {
    return action.payload?.selectedUserSchool;
}

function setCurrentUser(action: any) {
    return action.payload?.user;
}
