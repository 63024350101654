export const NavigationItems = [
  {
    title: 'Parent Section',
    label: 'menu.parent_section',
    allowedGroups: ['Parents'],
    allowedRights: []
  },
  {
    title: 'Dashboard',
    label: 'menu.dashboard',
    tags: 'Dashboard',
    icon: 'fal fa-tachometer-alt',
    routerLink: '/dashboard',
    allowedGroups: ['Parents', 'Administrators', 'SA', 'Students', 'Teachers', 'Users'],
    allowedRights: []
  },
  {
    title: 'Joined Schools',
    label: 'menu.joined_schools',
    tags: 'joined schools',
    icon: 'fal fa-lg fa-fw fa-school',
    routerLink: '/management/user-mgnt/users/user/schools',
    allowedGroups: ['Parents'],
    allowedRights: []
  },
  {
    title: 'Joined Children',
    label: 'menu.joined_children',
    tags: 'joined children',
    icon: 'fal fa-lg fa-fw fa-user',
    routerLink: '/management/user-mgnt/users/user/linked-children',
    allowedGroups: ['Parents'],
    allowedRights: []
  },
  {
    title: 'Student Section',
    label: 'menu.student_section',
    allowedGroups: ['Students'],
    allowedRights: []
  },
  {
    title: 'Virtual Classroom',
    label: 'menu.virtual_classroom',
    tags: 'Virtual Classroom',
    icon: 'fal fa-lg fa-fw fa-school',
    routerLink: '/settings/how-it-works',
    allowedGroups: ['Students'],
    allowedRights: []
  },
  {
    title: 'My Planer',
    label: 'menu.my_planer',
    tags: 'My planer',
    icon: 'fal fa-lg fa-fw fa-school',
    routerLink: '/settings/how-it-works',
    allowedGroups: ['Students'],
    allowedRights: []
  },
  {
    title: 'Teacher Section',
    label: 'menu.teacher',
    allowedGroups: ['Teachers'],
    allowedRights: []
  },
  {
    title: 'My Classrooms',
    label: 'menu.my_classgroups',
    tags: 'Classgroups',
    icon: 'fa fa-lg fa-fw fa-tachometer',
    routerLink: '/management/user-mgnt/users/user/school-groups',
    allowedGroups: ['Teachers'],
    allowedRights: []
  },
  {
    title: 'My Planer',
    label: 'menu.my_planer',
    tags: 'Classrooms',
    icon: 'fa fa-lg fa-fw fa-tachometer',
    routerLink: '/classrooms',
    allowedGroups: ['Teachers'],
    allowedRights: []
  },
  {
    title: 'Super Admin Section',
    label: 'menu.super_admin_section',
    allowedGroups: ['SA'],
    allowedRights: []
  },
  {
    title: 'countries Management',
    label: 'menu.countries_management',
    tags: 'Advisory Board',
    icon: 'fa fa-lg fa-fw fa-tachometer',
    allowedGroups: ['SA'],
    allowedRights: [],
    items: [
      {
        title: 'Countries',
        label: 'menu.countries',
        tags: 'theme settings how it works',
        routerLink: '/settings/how-it-works'
      },
      {
        title: 'Country\'s States',
        label: 'menu.country_states',
        tags: 'theme settings how it works',
        items: [
          {
            title: 'states',
            label: 'menu.states',
            tags: 'theme settings how it works',
            routerLink: '/settings/how-it-works'
          },
          {
            title: 'add new State',
            label: 'menu.add_new_state',
            tags: 'theme settings how it works',
            routerLink: '/settings/how-it-works'
          },
          {
            title: 'State Districts',
            label: 'menu.districts',
            tags: 'theme settings how it works',
            routerLink: '/settings/how-it-works'
          },
          {
            title: 'Create a Disctrict',
            label: 'menu.create_new_districts',
            tags: 'theme settings how it works',
            routerLink: '/settings/how-it-works'
          },
        ]
      },
      {
        title: 'Create a country',
        label: 'menu.create_new_country',
        tags: 'theme settings how it works',
        routerLink: '/settings/how-it-works'
      }
    ]
  },
  {
    title: 'Schools Management',
    label: 'menu.school_management',
    tags: 'schools',
    icon: 'fa fa-lg fa-fw fa-tachometer',
    allowedGroups: ['SA'],
    allowedRights: [],
    items: [
      {
        title: 'Import Schools',
        label: 'menu.school_import_xlsx',
        tags: 'Import schools data from Excel',
        routerLink: '/management/schools/excel-importer'
      },
      {
        title: 'Schools',
        label: 'menu.schools',
        tags: 'theme settings how it works',
        routerLink: '/management/schools/all'
      },
      {
        title: 'Create a School',
        label: 'menu.school_create',
        tags: 'theme settings how it works',
        routerLink: '/settings/how-it-works'
      }
    ]
  },
  {
    title: 'School manager Section',
    label: 'menu.school_manager_section',
    allowedGroups: ['Administrators'],
    allowedRights: []
  },
  {
    title: 'School Management',
    label: 'menu.school_management',
    tags: 'Advisory Board',
    icon: 'fal fa-lg fa-fw fa-graduation-cap',
    allowedGroups: ['Administrators'],
    allowedRights: [],
    items: [
      {
        title: 'My school',
        label: 'menu.my_school',
        tags: 'theme settings how it works',
        routerLink: '/management/school-admin/school'
      },
      {
        title: 'My Teachers',
        label: 'menu.my_teachers',
        tags: 'theme settings how it works',
        routerLink: '/management/school-admin/teachers'
      },
      {
        title: 'My Students',
        label: 'menu.my_students',
        tags: 'theme settings how it works',
        routerLink: '/management/school-admin/students'
      }
    ]
  },
  {
    title: 'User Management',
    label: 'menu.user_management',
    tags: 'Advisory Board',
    icon: 'fal fa-lg fa-fw fa-users',
    allowedGroups: ['Administrators'],
    allowedRights: [],
    items: [
      {
        title: 'School users',
        label: 'menu.school_users',
        tags: 'theme settings how it works',
        routerLink: '/management/school-admin/users'
      }
    ]
  }
];
