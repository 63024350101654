import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  Action
} from '@ngrx/store';

import { environment } from '../../../environments/environment';

import * as auth from './auth';
import * as registerUser from './register';
import * as advisory from './advisory';
import * as notify from './notify';
import * as profile from './profile';
import * as settings from '../../store/settings';
import * as navigation from '../../store/navigation';
import * as router from '../../store/router';
import * as location from './location';
import * as schoolGroup from './schoolGroup';
import * as management from './management';

import { RouterReducerState } from '@ngrx/router-store';

export interface AppState {
  navigation: navigation.NavigationState;
  router: RouterReducerState<router.RouterStateUrl>;
  auth: auth.AuthState;
  registerUser: registerUser.RegisterationState;
  advisory: advisory.AdvisoryState;
  notify: notify.NotifyState;
  profile: profile.ProfileState;
  // calendar: calendar.CalendarState;
  // schoolyear: schoolyear.SchoolYearState;
  settings: settings.SettingsState;
  location: location.LocationState;
  schoolgroup: schoolGroup.SchoolGroupState;
  management: management.ManagementState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: auth.authReducer,
  registerUser: registerUser.reducer,
  advisory: advisory.AdvisoryReducer,
  notify: notify.notifyReducer,
  profile: profile.profileReducer,
  // calendar: calendar.calendarReducer,
  // schoolyear: schoolyear.SchoolYearReducer,
  settings: settings.reducer,
  navigation: navigation.reducer,
  router: router.reducer,
  location: location.reducer,
  schoolgroup: schoolGroup.reducer,
  management: management.reducer
};

// console.log all actions
export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state: AppState, action: any): AppState => {
    if (
      // !action.silent &&
      environment.log.store
    ) {
      console.log('\nstate', state);
      console.log('+ action', action);

    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];

export const effects = [
  auth.AuthEffects,
  registerUser.RegisterEffects,
  advisory.AdvisoryEffeccts,
  notify.NotifyEffects,
  profile.ProfileEffects,
  // calendar.CalendarEffects,
  // schoolyear.SchoolYearEffects,
  navigation.NavigationEffects,
  // router.RouterEffects
  location.LocationEffects,
  schoolGroup.SchoolGroupEffects,
  management.ManagementEffects
];

export const services = [notify.NotifyService];
