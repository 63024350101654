import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  DataPage,
  ApiResponse,
  SchoolClass,
  School,
  Student,
  User,
  UserSchoolGroup,
  SchoolGroupName,
  SchoolClassGroupInfo
} from 'src/app/libs/shared/models';
import { CreateSchoolClassGroup } from 'src/app/libs/shared/models/create-school-class-group.request';
import { NotifyService } from '../store/notify';
import { SchoolClassGroup } from 'src/app/libs/shared/models/user.model';
import { SchoolGroupInfo } from 'src/app/libs/shared/models/school-group-info-model';

@Injectable()
export class SchoolService {


  private schoolsSubject: BehaviorSubject<DataPage<School>>;
  public schools: Observable<DataPage<School>>;

  private schoolClassesSubject: BehaviorSubject<ApiResponse<SchoolClass[]>>;
  public schoolClasses: Observable<ApiResponse<SchoolClass[]>>;

  private schoolInfoListSubject: BehaviorSubject<ApiResponse<School[]>>;
  public schoolsInfoList: Observable<ApiResponse<School[]>>;

  private schoolclassStudentsSubject: BehaviorSubject<ApiResponse<Student[]>>;
  public schoolclassStudents: Observable<ApiResponse<Student[]>>;

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    /**
     * initialize the School list subject and Observable
     */
    this.schoolsSubject = new BehaviorSubject<DataPage<School>>(new DataPage());
    this.schools = this.schoolsSubject.asObservable();

    this.schoolInfoListSubject = new BehaviorSubject<ApiResponse<School[]>>(
      new ApiResponse<School[]>()
    );
    this.schoolsInfoList = this.schoolInfoListSubject.asObservable();

    this.schoolClassesSubject = new BehaviorSubject<ApiResponse<SchoolClass[]>>(
      new ApiResponse<SchoolClass[]>()
    );
    this.schoolClasses = this.schoolClassesSubject.asObservable();

    this.schoolclassStudentsSubject = new BehaviorSubject<
      ApiResponse<Student[]>
    >(new ApiResponse<Student[]>());
    this.schoolclassStudents = this.schoolclassStudentsSubject.asObservable();
  }

  schoolList() {
    const url = `${this.config.baseUrl}/schools`;
    return this.httpClient.get<DataPage<School>>(url).pipe(
      map((result: DataPage<School>) => {
        this.schoolsSubject.next(result);
      }),
      catchError(error => of(console.log(error)))
    );
  }

  schoolListByCountry(countryId: number, page: number, size: number) {
    const url = `${this.config.baseUrl}/Schools/${page}/${size}/ByCountry/${countryId}`;

    return this.httpClient.get<DataPage<School>>(url).pipe(
      map((result: DataPage<School>) => {
        this.schoolsSubject.next(result);
        return result;
      }),
      catchError(error => of(console.log(error)))
    );
  }

  schoolListByDistrict(districtId: number, page: number, size: number) {
    const url = `${this.config.baseUrl}/Schools/${page}/${size}/ByDisctrict/${districtId}`;

    return this.httpClient.get<DataPage<School>>(url).pipe(
      map((result: DataPage<School>) => {
        this.schoolsSubject.next(result);
      }),
      catchError(error => of(console.log(error)))
    );
  }

  schoolListByState(stateId: number, page: number, size: number) {
    const url = `${this.config.baseUrl}/Schools/${page}/${size}/ByState/${stateId}`;

    return this.httpClient.get<DataPage<School>>(url).pipe(
      map((result: DataPage<School>) => {
        return result;
      }),
      catchError(error => of(console.log(error)))
    );
  }

  schoolById(id) { }

  getSchoolNameListByDistrictId(districtId: number) {
    const url = `${this.config.baseUrl}/schools/infobydistrict/${districtId}`;
    return this.httpClient.get<ApiResponse<School[]>>(url).pipe(
      map(result => {
        this.schoolInfoListSubject.next(result);
      }),
      catchError(error => of(console.log(error)))
    );
  }

  schoollistByTownNames(townNames: string[], page: any, size: any) {
    const names = townNames.join(',');
    const url = `${this.config.baseUrl}/schools/${page}/${size}/ByTownNames/${names}`;

    return this.httpClient.get<DataPage<School>>(url);
  }

  getSchoolNameListByDistrict(districtId: number) {
    const url = `${this.config.baseUrl}/schools/ByDistrict/${districtId}`;
    return this.httpClient.get<ApiResponse<School[]>>(url).pipe(
      map(response => response.data)
    );
  }
  getSchoolNamesByTown(town: string) {
    const url = `${this.config.baseUrl}/schools/ByTown/${town}`;

    return this.httpClient.get<ApiResponse<School[]>>(url).pipe(
      map(response => response.data)
    );
  }

  getSchoolClasses(schoolId: number) {
    const url = `${this.config.baseUrl}/schools/classes/${schoolId}`;
    return this.httpClient.get<ApiResponse<SchoolClass[]>>(url).pipe(
      map(response => {
        this.schoolClassesSubject.next(response);
        return response.data;
      })
    );
  }
  getSchoolClassGroupNames(schoolId: number) {
    const url = `${this.config.baseUrl}/schools/Classes/Groups/NamesBySchoolId/${schoolId}`;
    return this.httpClient.get<ApiResponse<SchoolClassGroup[]>>(url);
  }

  getSchoolGroupInfoListBySchoolId(schoolId: number, page: number, pageSize: number): any {
    // Groups/{schoolId}/Admin/{page}/{pageSize}
    const url = `${this.config.baseUrl}/schools/Groups/${schoolId}/Admin/${page}/${pageSize}`;
    return this.httpClient.get<ApiResponse<SchoolGroupInfo[]>>(url);
  }

  getSchoolClassGroupInfoListBySchoolId(schoolId: any, page: any, pageSize: any) {
    const url = `${this.config.baseUrl}/schools/ClassGroups/${schoolId}/Admin/${page}/${pageSize}`;
    return this.httpClient.get<ApiResponse<SchoolClassGroupInfo[]>>(url);
  }


  getSchoolClassGroupDetails(id: string) {
    const url = `${this.config.baseUrl}/schools/Classes/Groups/${id}`;

    return this.httpClient.get<ApiResponse<SchoolClassGroup>>(url);
  }

  getschoolClassStudents(schoolClassId: number) {
    const url = `${this.config.baseUrl}/schools/classes/students?class_id=${schoolClassId}`;

    return this.httpClient.get<ApiResponse<Student[]>>(url).pipe(
      map(result => {
        this.schoolclassStudentsSubject.next(result);
      })
    );
  }



  createSchool(school: School): any {
    const url = `${this.config.baseUrl}/schools`;
    return this.httpClient.post<any>(url, school);
  }

  importSchools(schools: any[]) {
    // to do
    const url = `${this.config.baseUrl}/schools`;

    return this.httpClient.post<any[]>(url, schools);
  }
  /**
   * create a user school group and return the updated user
   */
  createUserSchoolGrp(request: any) {


    const url = `${this.config.baseUrl}/schools/Groups`;

    return this.httpClient.post<User>(url, request).pipe(
      map(response => {

        return response;
      })
    );
  }

  createSchoolClassGroup(schoolClassGroup: CreateSchoolClassGroup): Observable<any> {

    const url = `${this.config.baseUrl}/schools/Classes/Groups`;
    return this.httpClient.post<CreateSchoolClassGroup>(url, schoolClassGroup);
  }

  getSchoolGroupDetails(grpId: number) {
    const url = `${this.config.baseUrl}/schools/Groups/GroupById/${grpId}`;

    return this.httpClient.get<ApiResponse<UserSchoolGroup>>(url).pipe(
      map((response) => response.data)
    );
  }

  // ==========================SCHOOL YEARS==========================
  getSchoolYears(schoolIDs: number[]): any {
    const ids = schoolIDs.join(',');
    const url = `${this.config.baseUrl}/SchoolYears/FilteredBySchoolIds/${ids}`;
    console.log(url);

    return this.httpClient.get<ApiResponse<School[]>>(url);
  }
  // ==========================SCHOOL YEARS==========================
}
