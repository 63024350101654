import { Component, OnInit } from '@angular/core';
import { UserFacade } from '../../facades/user-management.facade';
import { AuthFacade } from '../../facades/auth.facade';
@Component({
  selector: 'smart-dropdown-user',
  templateUrl: './dropdown-user.component.html',
})
export class DropdownUserComponent implements OnInit {


  vm$ = this.userFacade.vm$;

  /**
   *
   */
  constructor(
    private userFacade: UserFacade,
    private authFacade: AuthFacade,
  ) { }

  ngOnInit(): void {
  }

  logout() {

    this.authFacade.logout();
  }

}
