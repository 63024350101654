import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class RefreshService {

    private PublicHolidaysSubject: BehaviorSubject<boolean>;
    public PublicHolidays: Observable<boolean>;

    constructor() { 
        this.PublicHolidaysSubject = new BehaviorSubject<boolean>(false);
        this.PublicHolidays = this.PublicHolidaysSubject.asObservable();
    }

    public refreshPublicHolidayList(){
        this.PublicHolidaysSubject.next(true);
    }
}