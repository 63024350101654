import { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getAuthState } from 'src/app/core/store/auth';
import { UserFacade } from 'src/app/libs/facades/user-management.facade';
import { selectNavigationItems, NavigationItem } from 'src/app/store/navigation';
import { selectSettingsState } from 'src/app/store/settings';
import { environment } from 'src/environments/environment';
import { UserSchoolGrant } from '../../models/user.model';

@Component({
  selector: 'smart-nav',
  templateUrl: './nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit {

  private vmSubject$: BehaviorSubject<{ items: NavigationItem[], minified: boolean }>;

  private allvm$ = this.store.select(
    createSelector(
      selectNavigationItems,
      selectSettingsState,
      getAuthState,
      (items, settings, userState) => ({
        items,
        minified: settings.minifyNavigation,
        currentUserGrps: userState.selectedUserSchool?.userSchoolGrants?.map(grant => grant.grantedGroupName),
        currentUserRights: userState.user?.userRights
      })
    )
  );

  vm$: Observable<{ items: NavigationItem[], minified: boolean }>;

  constructor(private store: Store<any>, private authFacade: UserFacade) {

    this.vmSubject$ = new BehaviorSubject<{ items: NavigationItem[], minified: boolean }>({ items: [], minified: false });
    this.vm$ = this.vmSubject$.asObservable();

    this.vm$.subscribe(items => {

      // if (environment.debug) {
      //   console.log('============Current user menu items============');
      //   console.log(items);
      //   console.log('============End Current user menu items========');
      // }
    });
  }

  ngOnInit(): void {

    this.allvm$.subscribe(all => {
      // console.log(all);
      if (all) {
        const vms = all.items.filter(item => all.currentUserGrps?.some(grp => this.asArray(item).includes(grp)));
        this.vmSubject$.next({ items: vms, minified: all.minified });
      }
    });

  }

  trackByFn(idx: number, item: NavigationItem) {
    return item.title + '_' + idx;
  }

  private asArray(nav: NavigationItem): Array<any> {

    if (environment.debug) {
      // console.log('Title =>' + nav.title + ', Groups => ' + nav.allowedGroups?.reduce((prev, current) => prev + ',' + current));
    }

    if (nav.allowedGroups === undefined) {
      return [];
    }
    return nav.allowedGroups;

  }
}
