import { Action } from '@ngrx/store';
import { User } from 'src/app/libs/shared/models';

export enum ProfileActionTypes {
  PROFILE_UPDATE = '[PROFILE] Update Action',
  PROFILE_UPDATE_USER = '[PROFILE] Update user profile',
  USERPROFILE_UPDATE_SUCCESS = '[PROFILE] Update Action Success',
  USERPROFILE_UPDATE_FAILED = '[PROFILE] Update Action Failure',
  USERPROFILE_RESTORE = '[PROFILE] Restore Action',
  UPDATE_STUDENT_LIST = '[PROFILE] Update Linked StudentList',
  STUDENT_LIST_UPDATED = '[PROFILE] Linked StudentList updated successfully',
  STUDENT_LIST_UPDATE_FAILED = '[PROFILE] Linked StudentList Update failed',
  UPDATE_SCHOOLS_LIST = '[PROFILE] Update Linked Schools',
  SCHOOL_LIST_UPDATED = '[PROFILE] Linked Schools updated successfully',
  SCHOOL_LIST_UPDATE_FAILED = '[PROFILE] Linked Schools Update failed',
  UPDATE_STUDENT_WITH_TEACHERS = '[Profile] Add Teacher to Student',
  UPDATE_STUDENT_WITH_TEACHERS_SUCCESS = '[Profile] Add Teacher to Student Success',
  UPDATE_STUDENT_WITH_TEACHERS_FAILED = '[Profile] Add Teacher to Student Failed',

  JOIN_SCHOOLS = '[Profile] Join School',
  JOIN_SCHOOLS_SUCCESS = '[Profile] Join School Success',
  JOIN_SCHOOLS_FAILED = '[Profile] Join School Failed',

  DISJOIN_SCHOOLS = '[Profile] Dijoin School',
  DISJOIN_SCHOOLS_SUCCESS = '[Profile] Dijoin School Success',
  DISJOIN_SCHOOLS_FAILED = '[Profile] Disjoin School Failed',

  CREATE_USER_SCHOOL_GRP = '[Profile] Create User school group',
  CREATE_USER_SCHOOL_GRP_SUCCESS = '[Profile] Create User school group success',
  CREATE_USER_SCHOOL_GRP_FAILED = '[Profile] Create User school group failed',

  UPDATE_USER_PROFILE = '[Profile] Update User Profile',
  UPDATE_USER_PROFILE_SUCCESS = '[Profile] Update User Profile_SUCCESS',
  UPDATE_USER_PROFILE_FAILED = '[Profile] Update User Profile_FAILED',
}

export class CreateUserSchoolGrp implements Action {
  readonly type = ProfileActionTypes.CREATE_USER_SCHOOL_GRP;
  constructor(readonly request: any) { }
}
export class CreateUserSchoolGrpSuccess implements Action {
  readonly type = ProfileActionTypes.CREATE_USER_SCHOOL_GRP_SUCCESS;
  constructor(readonly user: User) { }
}
export class CreateUserSchoolGrpFailed implements Action {
  readonly type = ProfileActionTypes.CREATE_USER_SCHOOL_GRP_FAILED;
  constructor(readonly error: any) { }
}

export class DisjoinSchool implements Action {
  readonly type = ProfileActionTypes.DISJOIN_SCHOOLS;
  constructor(readonly userId: number, readonly schoolId: number, readonly ignoreIdentity: boolean) { }
}
export class DijoinSchoolSuccess implements Action {
  readonly type = ProfileActionTypes.DISJOIN_SCHOOLS_SUCCESS;
  constructor(readonly payload: User) { }
}
export class DijoinSchoolFailed implements Action {
  readonly type = ProfileActionTypes.DISJOIN_SCHOOLS_FAILED;
  constructor(readonly error: any) { }
}

export class JoinSchool implements Action {
  readonly type = ProfileActionTypes.JOIN_SCHOOLS;
  constructor(readonly userId: number, readonly schoolId: number, readonly ignoreIdentity: boolean) { }
}

export class JoinSchoolSuccess implements Action {
  readonly type = ProfileActionTypes.JOIN_SCHOOLS_SUCCESS;
  constructor(readonly payload: User) { }
}

export class JoinSchoolFailed implements Action {
  readonly type = ProfileActionTypes.JOIN_SCHOOLS_FAILED;
  constructor(readonly error: any) { }
}
export class UpdateStudentWithHisTeachers implements Action {
  readonly type = ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS;
  constructor(readonly payload) { }
}
export class UpdateStudentWithHisTeachersSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS_SUCCESS;
  constructor(readonly payload) { }
}
export class UpdateStudentWithHisTeachersFailed implements Action {
  readonly type = ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS_FAILED;
  constructor(readonly payload) { }
}
// ==========================USER PROFILE UPDATE======================

export class UserProfileUpdate implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE;
  constructor(readonly payload: any) { }
}

export class UserProfileUpdateSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE_SUCCESS;
  constructor(readonly user: User) { }
}

export class UserProfileUpdateFailed implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE_FAILED;
  constructor(readonly error: any) { }
}

export class ProfileUpdate implements Action {
  readonly type = ProfileActionTypes.PROFILE_UPDATE;
  constructor(readonly payload) { }
}
export class ProfileUpdateUser implements Action {
  readonly type = ProfileActionTypes.PROFILE_UPDATE_USER;
  constructor(readonly user: any) { }
}

export class ProfileUpdateSuccess implements Action {
  readonly type = ProfileActionTypes.USERPROFILE_UPDATE_SUCCESS;
  constructor() { }
}

export class ProfileUpdateFailure implements Action {
  readonly type = ProfileActionTypes.USERPROFILE_UPDATE_FAILED;
  constructor(readonly payload) { }
}

export class ProfileRestore implements Action {
  readonly type = ProfileActionTypes.USERPROFILE_RESTORE;
  constructor(readonly payload: User) { }
}
// ==========================USER PROFILE UPDATE======================

// ==========================LINKED STUDENT LIST =====================
export class UpdateLinkedStudentListAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_STUDENT_LIST;
  constructor(readonly payload: any) { }
}
export class LinkedStudentListUpdatedAction implements Action {
  readonly type = ProfileActionTypes.STUDENT_LIST_UPDATED;
  constructor(readonly payload: any) { }
}
export class LinkedStudentListUpdateFailedAction implements Action {
  readonly type = ProfileActionTypes.STUDENT_LIST_UPDATE_FAILED;
  constructor(readonly payload: any) { }
}

// ==========================LINKED STUDENT LIST =====================

// ==========================LINKED SCHOOL LIST ======================
export class UpdateLinkedSchoolListAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_SCHOOLS_LIST;
  constructor(readonly payload: any) { }
}
export class LinkedSchoolListUpdatedAction implements Action {
  readonly type = ProfileActionTypes.SCHOOL_LIST_UPDATED;
  constructor(readonly payload: any) { }
}
export class LinkedSchoolListUpdateFailedAction implements Action {
  readonly type = ProfileActionTypes.SCHOOL_LIST_UPDATE_FAILED;
  constructor(readonly payload: any) { }
}
// ==========================LINKED SCHOOL LIST ======================
export type ProfileActions =
  | CreateUserSchoolGrp
  | CreateUserSchoolGrpSuccess
  | CreateUserSchoolGrpFailed
  | ProfileUpdate
  | ProfileUpdateUser
  | ProfileRestore
  | ProfileUpdateSuccess
  | ProfileUpdateFailure
  | UpdateLinkedSchoolListAction
  | LinkedSchoolListUpdatedAction
  | LinkedSchoolListUpdateFailedAction
  | UpdateLinkedStudentListAction
  | LinkedStudentListUpdatedAction
  | LinkedStudentListUpdateFailedAction
  | UpdateStudentWithHisTeachers
  | UpdateStudentWithHisTeachersSuccess
  | UpdateStudentWithHisTeachersFailed
  | UserProfileUpdate
  | UserProfileUpdateSuccess
  | UserProfileUpdateFailed;
