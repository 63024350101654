import * as location from './location.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';


export const getLocationState = createFeatureSelector<location.LocationState>('location');

export const getCountriesMinified = createSelector(getLocationState, state => state.locationFilter.loadedCountries);
export const getStatesByCountryMinified = createSelector(getLocationState, state => state.locationFilter.loadedStates);
export const getTownNamesByStateMinified = createSelector(getLocationState, state => state.locationFilter.loadedTownNames);
export const loading = createSelector(getLocationState, state => state.locationFilter.loading);
export const error = createSelector(getLocationState, state => state.locationFilter.error);

export const getfiltredSchoolListByPage = createSelector(getLocationState, state => state.filtredSchoolsRes.filtredSchools);
export const schoolFilterLoading = createSelector(getLocationState, state => state.filtredSchoolsRes?.loading);
export const schoolFiltererror = createSelector(getLocationState, state => state.filtredSchoolsRes.error);




