import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, filter, map, catchError, switchMap } from 'rxjs/operators';
import * as fromAuth from '../auth';

import * as actions from './profile.actions';
import { of } from 'rxjs';
import { UserService } from '../../services';
import { SchoolService } from '../../services/school.service';
import { User } from 'src/app/libs/shared/models/user.model';
import { ApiResponse } from 'src/app/libs/shared/models';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class ProfileEffects {

  @Effect()
  profileUpdateModel$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.PROFILE_UPDATE),
    map((_: any) => _.payload),
    map(data => new actions.ProfileUpdateSuccess()),
  );

  @Effect()
  profileUserUpdate$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.PROFILE_UPDATE_USER),
    map((action: any) => {
      return new actions.ProfileUpdateSuccess();
    }),
  );

  @Effect()
  updateUserProfile$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.UPDATE_USER_PROFILE),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return this.userService.updateUserProfile(payload.profileData, payload.userId)
        .pipe(
          map((response: ApiResponse<User>) => {
            console.log(response);
            return new actions.UserProfileUpdateSuccess(response.data);
          }),
          catchError(error => of(new actions.UserProfileUpdateFailed(error)))
        );

    })
  );

  @Effect()
  effect$ = this.actions$.pipe(
    ofType(fromAuth.AuthActionTypes.AuthTokenPayload),
    filter(_ => !!_),
    map((_: any) => _.payload),
    map(data => {
      return new actions.ProfileRestore(data);
    })
  );

  @Effect()
  effectAuthTokenRestore$ = this.actions$.pipe(
    ofType(fromAuth.AuthActionTypes.TokenRestore),
    filter(_ => !!_),
    map((_: any) => _.payload),
    map(data => new actions.ProfileRestore(data.user)),
  );

  @Effect({ dispatch: true })
  decorateStudentWithTeachers = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.UPDATE_STUDENT_WITH_TEACHERS),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return this.userService.getTeachers(payload.schoolClassId, payload.schoolYearId)
        .pipe(
          map((response: any) => {
            return new actions.UpdateStudentWithHisTeachersSuccess({ studentId: payload.studentId, teachers: response.data });
          })
        );
    }),
    catchError(error => of(new actions.UpdateStudentWithHisTeachersFailed(error))));


  @Effect()
  updatelinkedStudentList$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.UPDATE_STUDENT_LIST)
  );

  @Effect()
  updatelinkedSchoolList$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.UPDATE_SCHOOLS_LIST)
  );

  @Effect()
  joinschool$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.JOIN_SCHOOLS),
    switchMap((payload: any) => {
      return this.userService.joinschool(payload.userId, payload.schoolId, payload.ignoreIdentity).pipe(
        map((user: any) => {
          console.log(user);
          return new actions.JoinSchoolSuccess(user);
        }),
        catchError((error: any) => of(new actions.JoinSchoolFailed(error)))
      );
    })
  );

  @Effect()
  joinSchoolSuccess$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.JOIN_SCHOOLS_SUCCESS),
    map((action: any) => {
      console.log(action);
      return new actions.ProfileUpdateUser(action.payload);
    })
  );

  @Effect()
  disjoinschool$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.DISJOIN_SCHOOLS),
    switchMap((payload: any) => {
      return this.userService.disjoinschool(payload.userId, payload.schoolId, payload.ignoreIdentity).pipe(
        map((user: any) => {
          return new actions.DijoinSchoolSuccess(user);
        }),
        catchError((error: any) => of(new actions.JoinSchoolFailed(error)))
      );
    })
  );

  @Effect()
  disjoinSchoolSuccess$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.DISJOIN_SCHOOLS_SUCCESS),
    map((action: any) => action.payload),
    map((user: any) => {
      return new actions.ProfileUpdateUser(user);
    })
  );

  @Effect()
  createUserSchoolGrp$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.CREATE_USER_SCHOOL_GRP),
    switchMap((payload: any) => {
      return this.schoolService.createUserSchoolGrp(payload.request).pipe(
        map((user: User) => {
          return new actions.CreateUserSchoolGrpSuccess(user);
        }),
        catchError((error: any) => of(new actions.CreateUserSchoolGrpFailed(error)))
      );
    })
  );

  @Effect()
  createUserSchoolGrpSuccess$ = this.actions$.pipe(
    ofType(actions.ProfileActionTypes.CREATE_USER_SCHOOL_GRP_SUCCESS),
    // map((action: any) => action.payload),
    map((payload: any) => {
      return new actions.ProfileUpdateUser(payload.user);
    })
  );



  constructor(
    private actions$: Actions,
    private userService: UserService,
    private schoolService: SchoolService,
    private configService: ConfigService
  ) { }
}
