import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ObservableInput } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  timeZone = 'Europe/Berlin';
  baseUrl: string;
  production: any;
  dev: any;
  integration: any;
  constructor(private httpClient: HttpClient) {
    DateTime.local().setZone(this.timeZone); // TODO remove it and get it from the account setting
    DateTime.local().setLocale('de-DE');

  }

  load(): Promise<boolean> {
    /* return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      setTimeout(() => resolve(true), 10000);
      console.log('load');
    }); */


    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      this.httpClient.get('./assets/config/config.json')
        .pipe(
          map((x: ConfigService) => {

            if (isDevMode) {
              this.baseUrl = x.dev.baseUrl;
            } else {
              this.baseUrl = x.production.baseUrl;
            }
            resolve(true);
          }),
          catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
            if (x.status !== 404) {
              resolve(false);
            }
            this.baseUrl = 'https://localhost:44359/api';
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  }

  get appImagesUrl() {
    return this.replaceLast('api', 'app-images', this.baseUrl);
  }

  private replaceLast(find, replace, str) {
    const lastIndex = str.lastIndexOf(find);

    if (lastIndex === -1) {
      return str;
    }

    const beginString = str.substring(0, lastIndex);
    const endString = str.substring(lastIndex + find.length);

    return beginString + replace + endString;
  }
}

// tslint:disable-next-line:ban-types
export function ConfigFactory(service: ConfigService): Function {
  // this.httpClient = httpClient;
  return () => service.load();
}
