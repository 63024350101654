import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { config } from './core/smartadmin.config';
import * as location from './core/store/location';
import { Store } from '@ngrx/store';
import { APP_CONFIG } from './app.config';
import { Title } from '@angular/platform-browser';
import { de_DE, en_US, fr_FR, NzI18nInterface, NzI18nService } from 'ng-zorro-antd/i18n';
import { UserFacade } from './libs/facades/user-management.facade';
@Component({
  selector: 'smart-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  title = APP_CONFIG.app;

  constructor(
    private userFacade: UserFacade,
    private translate: TranslateService,
    private locationStore: Store<location.LocationState>,
    private titleService: Title, private i18n: NzI18nService) {

    this.titleService.setTitle(this.title);

    this.userFacade.observaleUser.subscribe(user => {
      if (user) {
        const locale = user.locale.substring(0, 2) || config.defaultLocale;

        translate.use(user.locale);

        switch (locale) {
          case 'de_DE':
            this.i18n.setLocale(de_DE);

            break;
          case 'en_US':
            this.i18n.setLocale(en_US);
            break;
          default:
            this.i18n.setLocale(de_DE);
        }
      }
    });
  }
}
