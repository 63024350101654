import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAdvisory from './index';
import { AdvisoryState } from "./advisory.reducer";
export enum AdvisoryActionTypes {
    LOAD_ADVISORY = "[Advisory] Load advisory",
    ADVISORY_LOADED_SUCESS = "[Advisory] Advisory Loading sucess",
    ADVISORY_LOADED_FAILED = "[Advisory] Advisory Loading Failed"
}

export class LoadAdAdvisoryBoardAction implements Action {
    readonly type = AdvisoryActionTypes.LOAD_ADVISORY;
    /**
     * 
     * @param payload : 
     */
    constructor(readonly payload: fromAdvisory.RequestParams) { }
}

export class LoadAdvisoryBoardSuccess implements Action {
    readonly type = AdvisoryActionTypes.ADVISORY_LOADED_SUCESS;
    constructor(readonly payload: fromAdvisory.ResponseModel) { }
}
export class LoadAdvisoryBoardFailed implements Action {
    readonly type = AdvisoryActionTypes.ADVISORY_LOADED_FAILED;
    constructor(readonly payload: any) { }
}

export const advisorystate = createFeatureSelector<any>('advisory');

export const geterrorstate =
    createSelector(advisorystate, (state: AdvisoryState) => state.error);
export const getloadingstate =
    createSelector(advisorystate, (state: AdvisoryState) => state.loading);
export const getmodelstate =
    createSelector(advisorystate, (state: AdvisoryState) => state.response_model);



export type AdvisoryAction =
    LoadAdAdvisoryBoardAction
    | LoadAdvisoryBoardSuccess
    | LoadAdvisoryBoardFailed;


