import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { RequestParams } from '../store/advisory';

@Injectable()
export class AdvisoryService {

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService) { }

    getbyClassAndSchoolYear(params: RequestParams) {

        // tslint:disable-next-line:max-line-length
        const url = `${this.configService.baseUrl}/advisoryboard/byclass?schoolclass_id=${params.schoolclass_id}&schoolyear_id=${params.schoolyear_id}`;

        return this.httpClient.get<any>(url);
    }



}