import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class UserActivationService {


    constructor(private httpClient: HttpClient, private config: ConfigService) { }

    getActivationData(token: string) {
        const url = `${this.config.baseUrl}/auth/recover/${token}`;

        return this.httpClient.get<any>(url);
    }

    requestSecurityCode(activationToken: string, userId: number) {

        const url = `${this.config.baseUrl}/auth/requestSecurityCode/${userId}/token/${activationToken}`;

        return this.httpClient.post<any>(url, {});
    }

    RecoverUser(activationRequest: { userId: any; token: string; securityCode: number; password: string; }) {

        const url = `${this.config.baseUrl}/auth/recover`;

        return this.httpClient.post<any>(url, activationRequest);

    }


}
