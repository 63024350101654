export class DataPage<T> {

    public data: T[];
    public currentPage: number;
    public firstPage: number;
    public lastPage: number;
    public perPage: number;
    public next: number;
    public previous: number;
    public totalPages: number;
    public total: number;
}
