import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  DataPage,
  ApiResponse,
  SchoolClass,
  School,
  Student,
  State,
  District
} from 'src/app/libs/shared/models';

import { map } from 'rxjs/operators';

@Injectable()
export class StatesService {


  private districtsSubject: BehaviorSubject<ApiResponse<District[]>>;
  public disctrictsResponse: Observable<ApiResponse<District[]>>;

  constructor(private httpClient: HttpClient, private config: ConfigService) {

    this.districtsSubject = new BehaviorSubject<ApiResponse<District[]>>(
      new ApiResponse()
    );
    this.disctrictsResponse = this.districtsSubject.asObservable();
  }

  /**
   *
   * page
   * per_page
   * http://localhost:8001/api/v1/states/getByPage?page=1&per_page=5
   */
  // tslint:disable-next-line:variable-name
  getStatesByPage(page: number, per_page: number): Observable<any> {
    const url = `${this.config.baseUrl}/states/getByPage?page=${page}&per_page${per_page}`;
    return this.httpClient.get<DataPage<any>>(url);
  }

  /**
   * returns an observable with the data
   * http://127.0.0.1:8001/api/v1/states/getByCountry?country_id=1
   * country_id
   */
  // tslint:disable-next-line:variable-name
  getStatesByCountry(country_id: number) {
    const url = `${this.config.baseUrl}/Locations/Countries/${country_id}/States`;
    return this.httpClient.get<ApiResponse<State[]>>(url);
  }

  // tslint:disable-next-line:variable-name
  getDistrictNameByState(state_id: number) {
    const url = `${this.config.baseUrl}/Locations/States/${state_id}/towns`;
    return this.httpClient.get<ApiResponse<string[]>>(url);
  }
}
