import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import * as actions from './management.actions';
import * as authactions from '../auth/auth.actions';
import { of } from 'rxjs';
// import all requried services or any dependencies

@Injectable()
export class ManagementEffects {
    constructor(private actions$: Actions) { }

    @Effect({ dispatch: true })
    restoreUser$ = this.actions$.pipe(
        ofType(authactions.AuthActionTypes.TokenRestore),
        map(action => actions.AuthUserChanged(action)),
        tap((action: any) => {
            console.log(action);
        })

    );
    @Effect({ dispatch: true })
    restoreSelectedSchool$ = this.actions$.pipe(
        ofType(authactions.AuthActionTypes.ChangeUserSchoolSelection),
        map(action => actions.AuthUserSchoolChanged(action)),
        map(_ => actions.RequestDashboardData())
    );
}
